import { useState, useEffect } from 'react'

import {useTranslation} from "react-i18next";

import { auth, sendPasswordReset } from "../../util/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import { Link, useNavigate } from "react-router-dom";

import BarLoader from "react-spinners/BarLoader";

import { Input, Button } from 'reactstrap';

function ResetPasswordPage() {
  const [email, setEmail] = useState("");
  // eslint-disable-next-line
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const {t, i18n} = useTranslation('common');
  const [language, setLanguage] = useState(i18n?.language)
  const toggleLanguage = () => {
    if (language === 'es'){
      setLanguage('en');
      i18n.changeLanguage('en');
    } else if (language === 'en'){
      setLanguage('es');
      i18n.changeLanguage('es')
    }
  }

  const navbarLanguageItem = () => {
    if (language === 'es'){
      return 'EN'
    } else if (language === 'en'){
      return 'ES'
    }
  }

  useEffect(() => {
    if (user) navigate("/dashboard");
  }, [user, navigate]);

  const resetPassword = () => {
    sendPasswordReset(email);
    navigate(-1);
  }

  if (loading) return (
    <div id="loginPage">
      <BarLoader color="#8A4B61" />
    </div>
  )

  return (
    <div>
      <p onClick={toggleLanguage} id='loginPageLanguageToggle'>
        {navbarLanguageItem()}
      </p>
    <div id="loginPage">   
      <div className='loginContainer'>
        <img
          alt="logo"
          src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1686676447/Gazindo%20Lite/logoPrimary.png"
          style={{
            height: 50,
          }}
        />
        <Input
          type="text"
          className="authInput"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t('yourEmail')}
        />     
        <Button className='primaryBtn' onClick={resetPassword} style={{marginTop: '0.5rem'}} disabled={!email}>
          {t('reset')}
        </Button>     
        <p>{t('dontHaveAnAccount')}? <Link className='authLink' to="/register">{t('register')}</Link> {t('now')}.</p>
      </div>
    </div>
    </div>
  );
}

export default ResetPasswordPage;