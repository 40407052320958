// import { useState, useEffect } from 'react'
import './App.css';

import { BrowserRouter as Router, Routes, Route, 
  // Link, useNavigate, 
  Navigate
 } from "react-router-dom";
import { auth, db
  // logInWithEmailAndPassword, registerWithEmailAndPassword, signInWithGoogle, sendPasswordReset
 } from "./util/firebase";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import BarLoader from "react-spinners/BarLoader";

import {useTranslation} from "react-i18next";

import { BsWhatsapp, BsGlobe } from 'react-icons/bs';
// import { MdNotificationsNone } from 'react-icons/md';
// import { GiHamburgerMenu } from 'react-icons/gi';
// import { BiHelpCircle } from 'react-icons/bi';
// import { IoDocumentTextOutline } from 'react-icons/io5'

// import { FaInstagram, FaFacebook, FaTiktok } from 'react-icons/fa';
// import { GoChevronDown, GoPlus } from 'react-icons/go';
// import { FiMoreVertical } from 'react-icons/fi';

import 'react-toastify/dist/ReactToastify.css';

// import { Link } from "react-scroll";

import { Container, Row, Col, Modal, ModalBody } from 'reactstrap';

import classnames from 'classnames'

import LoginPage from './pages/auth/LoginPage';

// import DashboardPage from './pages/DashboardPage'
// import NotificationsPage from './pages/NotificationsPage';
import HelpPage from './pages/HelpPage';
// import LanguagePage from './pages/LanguagePage';
import AccountPage from './pages/AccountPage';
// import ServicesPage from './pages/ServicesPage';
// import DataPage from './pages/DataPage';

// import { useAuth0 } from "@auth0/auth0-react";
// import MarketplacePage from './pages/MarketplacePage';
import OrdersPage from './pages/orders/OrdersPage';
import AnalyticsPage from './pages/AnalyticsPage';
import CreateOrderPage from './pages/orders/CreateOrderPage';
import CompanyPage from './pages/CompanyPage';
import SingleOrderPage from './pages/orders/SingleOrderPage';
import { useEffect, useState } from 'react';
import RegisterPage from './pages/auth/RegisterPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';

import { query, collection, getDocs, where, doc, updateDoc } from "firebase/firestore";
import ProductBasePage from './pages/ProductBasePage';
import DashboardPage from './pages/DashboardPage';
import { IoClose } from 'react-icons/io5';

function App() {
  // const { isAuthenticated, isLoading } = useAuth0();
  // const auth = getAuth();
  
  // if (isLoading) return <Loading/>

  const [user, loading, 
    // error
  ] = useAuthState(auth);

  if (loading) return (
		<div id="loginPage">
			<BarLoader color="#6b64ff" />
		</div>
	)

  return (
    user ? <Home/> : <AuthRoutes />
  );
}

function AuthRoutes() {
  return (
    <Router>
      <Routes>
        <Route path='/*' element={<Navigate to='/login' />}/>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/reset" element={<ResetPasswordPage />} />
      </Routes>
    </Router>
  )
}

function Home() {
  const [user] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(false);

  const [languageModalIsOpen, setLanguageModalIsOpen] = useState(false);
  const toggleLanguageModalIsOpen = () => {
    setLanguageModalIsOpen(!languageModalIsOpen);
  }

  const {t, i18n} = useTranslation('common');
  console.log(i18n?.language)
  const [language, setLanguage] = useState()

  const fetchUserLanguage = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      i18n.changeLanguage(data.language);
      setLanguage(data.language);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user language");
    }
  };

  useEffect(() => {
    fetchUserLanguage();
    // eslint-disable-next-line
  }, []);

  const toggleLanguage = async (newLanguage) => {
    if(!(language === newLanguage)){
    try {
      setIsLoading(true);
      const userRef = query(collection(db, "users"), where("uid", "==", user?.uid));
      const findUsers = await getDocs(userRef);
      setLanguage(newLanguage);
      i18n.changeLanguage(newLanguage);
      findUsers.forEach( async (user) => {
        const getUser = doc(db, 'users', user.id);
        await updateDoc(getUser, {
          language: newLanguage
        });
      });
      toggleLanguageModalIsOpen()
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while updating user data");
    }
    }
  }

  // const [isOpen, setIsOpen] = useState(false);

  // const toggle = () => setIsOpen(!isOpen);

  // const { user } = useAuth0();

  if (isLoading) return (
    <div id="loginPage">
      <BarLoader color="#6b64ff" />
    </div>
  )

  return (
    <div className="App">
      <section id='heroSection'>
        <div id='myNavbar'>
          <a href='/'>
            <img
              alt="logo"
              src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1686676447/Gazindo%20Lite/logoPrimary.png"
              style={{
                height: 25,
              }}
            />
          </a>
          <div>
            <a className='navLink hideOnMobile' href='/'>
              Dashboard
            </a>
            <a className='navLink hideOnMobile' href='/orders'>
              {t('navbar.ordersItem')}
            </a>
            <a className='navLink hideOnMobile' href='/analytics'>
              {t('navbar.analyticsItem')}
            </a>
            <a className='navLink hideOnMobile' href='/help'>
              {t('navbar.helpItem')}
            </a>
            <a className='navLink hideOnMobile' href='/account'>
              {t('navbar.accountItem')}
            </a>
            {/* eslint-disable-next-line */}
            <a onClick={toggleLanguageModalIsOpen} className='navLink' style={{cursor: 'pointer'}}>
              <BsGlobe style={{width: 20, height: 20}} />
            </a>
            <Modal isOpen={languageModalIsOpen} toggle={toggleLanguageModalIsOpen} id='changeLanguageModal'>
              {/* <ModalHeader toggle={toggleLanguageModalIsOpen}></ModalHeader> */}
              <ModalBody>
                <div className='modalHeader' style={{marginBottom: '1rem'}}>
									<div>
										<h1>{t('language')}</h1>																						
									</div>
									<IoClose onClick={toggleLanguageModalIsOpen} />
								</div>
                <div onClick={() => toggleLanguage('en')} className={classnames(
                  "languageCard",
                  {
                   	active: language === 'en'
                  }
                )}>
                  <p>
                    {t('english')}
                  </p>
                </div>
                <div onClick={() => toggleLanguage('es')} className={classnames(
                  "languageCard",
                  {
                   	active: language === 'es'
                  }
                )}>
                  <p>
                    {t('spanish')}
                  </p>
                </div>
                <div onClick={() => toggleLanguage('de')} className={classnames(
                  "languageCard",
                  {
                   	active: language === 'de'
                  }
                )} style={{marginBottom: 0}}>
                  <p>
                    {t('german')}
                  </p>
                </div>
              </ModalBody>
            </Modal>
            {/* <button onClick={() => i18n.changeLanguage('es')}>de</button>
            <button onClick={() => i18n.changeLanguage('en')}>en</button> */}
            <a href='/orders/create' className='hideOnMobile'>
              <button id='createOrderBtn'>
                {t('navbar.createOrderItem')}
              </button>
            </a>
          </div>        
        </div>
        <div id='mobileSubNavbar' className='hideOnDesktop'>
            <a className='navLink' href='/'>
              Dashboard
            </a>
            <a className='navLink' href='/orders'>
              {t('navbar.ordersItem')}
            </a>
            <a className='navLink' href='/analytics'>
              {t('navbar.analyticsItem')}
            </a>
            <a className='navLink' href='/help'>
              {t('navbar.helpItem')}
            </a>
            <a className='navLink' href='/account'>
              {t('navbar.accountItem')}
            </a>
        </div>
        {/* <Navbar expand="md">
          <NavbarBrand href="/">
            <img
              alt="logo"
              src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1684735008/SAYVIOUR/Vector_1_av7kgu.png"
              style={{
                height: 20,
              }}
            />
          </NavbarBrand>
          <GiHamburgerMenu id='navbarToggler' onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav style={{marginLeft: 'auto'}} navbar>         
            <NavItem>
              <a href='/services'>
                <BsGrid3X3Gap id='navbarServicesItemIcon' />
                Services
              </a>              
            </NavItem>
            <NavItem>
              <a href='/data'>
                <IoDocumentTextOutline id='navbarServicesItemIcon' />
                Data
              </a>              
            </NavItem>
            <NavItem>              
              <a href='/notifications'>
                <MdNotificationsNone />
                <span>
                  Notifications
                </span>
              </a>
            </NavItem>
            <NavItem>              
              <a href='/help'>
                <BiHelpCircle />
                <span>
                  Help
                </span>
              </a>
            </NavItem>
            <NavItem>              
              <a href='/language'>
                Language
              </a>
            </NavItem>
            <NavItem>          
              <a href='/account'>
                Username
              </a>
            </NavItem>
            </Nav>
          </Collapse>
        </Navbar> */}
        <Router>
          <Routes>
            <Route path='/login' element={<Navigate to='/orders' />}/>
            <Route path='/register' element={<Navigate to='/orders' />}/>
            <Route path='/reset' element={<Navigate to='/orders' />}/>
            <Route path='/' element={<DashboardPage />}/>
            {/* <Route path="/" element={<DashboardPage />} /> */}
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/orders/:orderId" element={<SingleOrderPage />} />
            <Route path="/analytics" element={<AnalyticsPage />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/company" element={<CompanyPage />} />
            <Route path="/productBase/:companyId" element={<ProductBasePage />} />
            <Route path='/orders/orders/create' element={<Navigate to='/orders/create' />}/>
            <Route path="/orders/create" element={<CreateOrderPage />} />
          </Routes>
        </Router>
      </section>
      <footer>
        <Container>
          <Row>
            <Col>
              <Row>
                <Col>
                  <img
                    alt="logo"
                    src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1686676447/Gazindo%20Lite/logoPrimary.png"
                    style={{
                      height: 25,
                    }}
                  />
                </Col>
                {/* <Col>
                  <h3>
                    Company
                  </h3>
                  <a href='https://sayviour.com/terms-of-service'>
                    Terms of service
                  </a>
                  <a href='https://sayviour.com/privacy-policy'>
                    Privacy policy
                  </a>
                </Col> */}
              </Row>
              <p>
                © 2023 Gazindo Lite, all rights reserved
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
      <a
        href="https://wa.me/4917623973014"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BsWhatsapp className="whatsapp-icon" />
      </a>
    </div>
  );
}

// function Login() {  
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   // eslint-disable-next-line
//   const [user, loading, error] = useAuthState(auth);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (user) navigate("/dashboard");
//   }, [user, navigate]);

//   if (loading) return <Loading/>

//   return (
//     <div id="loginPage">   
//       <div>
//         <div>
//           <h1>Welcome<br />to</h1>
//           <img
//             alt="logo"
//             src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1686263563/SAYVIOUR/Vector_4_uclh1g.png"
//             style={{
//             height: 30,
//           }}/>
//         </div>
//         <Input
//           type="text"
//           className="login__textBox"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           placeholder="E-mail Address"
//         />
//         <Input
//           type="password"
//           className="login__textBox"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           placeholder="Password"
//         />
//         <Button onClick={() => logInWithEmailAndPassword(email, password)}>
//           Login
//         </Button>
//         <Button onClick={signInWithGoogle}>
//           Login with Google
//         </Button>
//         <Link to="/reset">Forgot Password</Link>
//         <p>Don't have an account? <Link to="/register">Register</Link> now.</p>
//       </div>
//     </div>
//   );
// }

// function Register() {  
//   // const { loginWithRedirect } = useAuth0();
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   // eslint-disable-next-line
//   const [user, loading, error] = useAuthState(auth);
//   const navigate = useNavigate();

//   const register = () => {
//     if (!name) alert("Please enter name");
//     registerWithEmailAndPassword(name, email, password);
//   };

//   useEffect(() => {
//     if (user) navigate("/dashboard");
//   }, [user, navigate]);

//   if (loading) return <Loading/>

//   return (
//     <div id="loginPage">   
//       <div>
//         <div>
//           <h1>Welcome<br />to</h1>
//           <img
//             alt="logo"
//             src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1686263563/SAYVIOUR/Vector_4_uclh1g.png"
//             style={{
//             height: 30,
//           }}/>
//         </div>
//         <Input
//           type="text"
//           className="register__textBox"
//           value={name}
//           onChange={(e) => setName(e.target.value)}
//           placeholder="Full Name"
//         />
//         <Input
//           type="text"
//           className="login__textBox"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           placeholder="E-mail Address"
//         />
//         <Input
//           type="password"
//           className="login__textBox"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           placeholder="Password"
//         />
//         <Button onClick={register}>
//           Register
//         </Button>
//         <Button onClick={signInWithGoogle}>
//           Register with Google
//         </Button>
//         <p>Already have an account? <Link to="/login">Login</Link> now.</p>
//       </div>
//     </div>
//   );
// }

// function Reset() {  
//   const [email, setEmail] = useState("");
//   // eslint-disable-next-line
//   const [user, loading, error] = useAuthState(auth);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (user) navigate("/dashboard");
//   }, [user, navigate]);

//   if (loading) return <Loading/>

//   return (
//     <div id="loginPage">   
//       <div>
//         <div>
//           <h1>Welcome<br />to</h1>
//           <img
//             alt="logo"
//             src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1686263563/SAYVIOUR/Vector_4_uclh1g.png"
//             style={{
//             height: 30,
//           }}/>
//         </div>
//         <Input
//           type="text"
//           className="login__textBox"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           placeholder="E-mail Address"
//         />        
//         <Button onClick={() => sendPasswordReset(email)}>
//           Send password reset email
//         </Button>        
//         <p>Don't have an account? <Link to="/register">Register</Link> now.</p>
//       </div>
//     </div>
//   );
// }

// function Loading() {
//   return (
//     <div className="App">
//       <section id='heroSection'>
//         <Navbar expand="md">
//           <NavbarBrand href="/">
//             <img
//               alt="logo"
//               src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1684735008/SAYVIOUR/Vector_1_av7kgu.png"
//               style={{
//                 height: 20,
//               }}
//             />
//           </NavbarBrand>
//         </Navbar>
//         <Container style={{height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>        
//           <PuffLoader color="#6b64ff" />
//         </Container>
//       </section>
//       <footer>
//         <Container>
//           <Row>
//             <Col>
//               <Row>
//                 <Col>
//                   <img
//                     alt="logo"
//                     src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1684735008/SAYVIOUR/Vector_1_av7kgu.png"
//                     style={{
//                       height: 20,
//                     }}
//                   />
//                 </Col>
//                 <Col>
//                   <h3>
//                     Company
//                   </h3>
//                   <a href='https://sayviour.com/terms-of-service'>
//                     Terms of service
//                   </a>
//                   <a href='https://sayviour.com/privacy-policy'>
//                     Privacy policy
//                   </a>
//                 </Col>
//               </Row>
//               <p>
//                 © 2023 SAYVIOUR, all rights reserved
//               </p>
//             </Col>
//           </Row>
//         </Container>
//       </footer>
//       <a
//         // href="https://wa.me/50378371283"
//         class="whatsapp_float"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         <BsWhatsapp className="whatsapp-icon" />
//       </a>
//     </div>
//   );
// }

export default App;