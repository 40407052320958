import { useState, useEffect } from 'react'

import {v4 as uuidv4} from 'uuid';

import {useTranslation} from "react-i18next";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../util/firebase";
import { query, collection, getDocs, where, updateDoc, doc, addDoc, deleteDoc } from "firebase/firestore";

import { IoClose } from 'react-icons/io5';

// import classnames from 'classnames'

import { Container, Row, Col, Button, Input, Modal, ModalBody } from 'reactstrap';

import Swal from 'sweetalert2'

// import { useAuth0 } from "@auth0/auth0-react";

import { useNavigate } from "react-router-dom";

import {logout} from "../util/firebase";

import BarLoader from "react-spinners/BarLoader";

const userFromDb = {
  accountId: '00000000',
  accountName: 'SAYVIOUR',
  password: 'password',
  name: 'Diego Spranger',
  email: 'sprangerventures@gmail.com',
  address: 'Calle La Mascota, Torre Triana, 7-C',
  city: 'San Salvador',
  state: 'San Salvador',
  postalCode: '00000',
  country: 'El Salvador',
  phoneNumber: '+503 7489 1591',
  companyName: 'SAYVIOUR',
  websiteUrl: 'https://sayviour.com/'
}

// const company = {
//   id: 1,
//   name: 'Recien Horneado Bakery',
//   countries:[
//     {
//       id: 1,
//       name: 'El Salvador',
//       branches: [
//         {
//           id: 1,
//           members: [
//             {
//               id: 1
//             },
//             {
//               id: 2
//             },
//             {
//               id: 3
//             },
//           ]
//         },
//         {
//           id: 2,
//           members: [
//             {
//               id: 1
//             },
//             {
//               id: 2
//             },
//             {
//               id: 3
//             },
//           ]
//         },
//         {
//           id: 3,
//           members: [
//             {
//               id: 1
//             },
//             {
//               id: 2
//             },
//             {
//               id: 3
//             },
//           ]
//         }
//       ]
//     },
//     {
//       id: 2,
//       name: 'Guatemala',
//       branches: [
//         {
//           id: 1,
//           members: [
//             {
//               id: 1
//             },
//             {
//               id: 2
//             },
//             {
//               id: 3
//             },
//           ]
//         }
//       ]
//     },
//     {
//       id: 3,
//       name: 'Mexico',
//       branches: [
//         {
//           id: 1,
//           members: [
//             {
//               id: 1
//             },
//             {
//               id: 2
//             },
//             {
//               id: 3
//             },
//           ]
//         }
//       ]
//     }
//   ]
// }

function AccountPage() {
  const [user] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(false);

  const {t} = useTranslation('common');

  const navigate = useNavigate();
  
  const [userData, setUserData] = useState();
  const [name, setName] = useState('Diego Spranger')
  const [email, setEmail] = useState('sprangerventures@gmail.com')
  const [phoneNumber, setPhoneNumber] = useState('+503 7489 1591')

  const [company, setCompany] = useState();
  const fetchCompanyData = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "companies"), where("members", "array-contains", user?.uid));
      const doc = await getDocs(q);
      if (doc.size > 0){
        const data = doc.docs[0].data();
        setCompany(data);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching company data");
    }
  };

  useEffect(() => {
    fetchCompanyData();
    // eslint-disable-next-line
  }, []);

  const [numberOfCountries, setNumberOfCountries] = useState(0)
  const fetchNumberOfCountries = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "countries"), where("companies", "array-contains", company?.uid));
      const docs = await getDocs(q);
      setNumberOfCountries(docs.docs.length)
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching number of countries");
    }
  }

  const [numberOfBranches, setNumberOfBranches] = useState(0);
  const [numberOfMembers, setNumberOfMembers] = useState(0);
  const fetchNumberOfBranchesAndMembers = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "branches"), where("companyUid", "==", company?.uid));
      const docs = await getDocs(q);
      setNumberOfBranches(docs.docs.length)
      let numOfMembers = 0
      docs.docs.forEach(document => {
        const branch = document.data();
        numOfMembers += branch.members.length;
      })
      setNumberOfMembers(numOfMembers)
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching number of countries");
    }
  }

  useEffect(() => {
    if(company){
      fetchNumberOfCountries();
      fetchNumberOfBranchesAndMembers();
    }
    // eslint-disable-next-line
  }, [company]);

  const fetchUserData = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setUserData(data);
      setName(data.name);
      setEmail(data.email);
      setPhoneNumber(data.phoneNumber);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (!user) return navigate("/");
    fetchUserData();
    // eslint-disable-next-line
  }, [user]);

  const [accessRequests, setAccessRequests] = useState();
  const fetchAccessRequests = async () => {
    try {
      setIsLoading(true);
      let fetchedAccessRequests = [];
      const accessRequestsRef = collection(db, "accessRequests");
      const q = query(accessRequestsRef, where("userUid", "==", user?.uid))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newAccessRequest = doc.data();
        fetchedAccessRequests.push(newAccessRequest);
      });

      setAccessRequests(fetchedAccessRequests)

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching access requests");
    }
  };
  useEffect(() => {
    fetchAccessRequests();
    // eslint-disable-next-line
  }, []);

  const updateUser = async () => {
    try {
      setIsLoading(true);
      const userRef = query(collection(db, "users"), where("uid", "==", user?.uid));
      const findUsers = await getDocs(userRef);
      findUsers.forEach( async (user) => {
        const getUser = doc(db, 'users', user.id);
        await updateDoc(getUser, {
          name: name,
          email: email,
          phoneNumber: phoneNumber
        });
      });
      fetchAccessRequests();
      Swal.fire({
        icon: 'success',
        text: t('userUpdatedSuccessfully'),
        confirmButtonColor: '#6b64ff',
        confirmButtonText: 'Okay'
      })
      fetchUserData();
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while updating user data");
    }  
  }

  // const [accountSettingsIsOpen, setAccountSettingsIsOpen] = useState(false)
  // const [contactInformationIsOpen, setContactInformationIsOpen] = useState(false)

  // const [accountId, setAccountId] = useState();
  // const [accountName, setAccountName] = useState();
  // const [password, setPassword] = useState();

  // const [name, setName] = useState();
  // const [address, setAddress] = useState();
  // const [city, setCity] = useState();
  // const [state, setState] = useState();
  // const [postalCode, setPostalCode] = useState();
  // const [country, setCountry] = useState();
  // const [phoneNumber, setPhoneNumber] = useState();
  // const [companyName, setCompanyName] = useState();
  // const [websiteUrl, setWebsiteUrl] = useState();

  // const [isEdittingAccountSettings, setIsEdittingAccountSettings] = useState(false);
  // const [isEdittingContactInformation, setIsEdittingContactInformation] = useState(false);

  useEffect(() => {
    if (userFromDb){
      // setAccountId(userFromDb.accountId);
      // setAccountName(userFromDb.accountName);
      // setPassword(userFromDb.password);

      // setName(userFromDb.name);
      // setAddress(userFromDb.address);
      // setCity(userFromDb.city);
      // setState(userFromDb.state);
      // setPostalCode(userFromDb.postalCode);
      // setCountry(userFromDb.country);
      // setPhoneNumber(userFromDb.phoneNumber);
      // setCompanyName(userFromDb.companyName);
      // setWebsiteUrl(userFromDb.websiteUrl);
    }
  }, [])

  // const toggleIsEditting = (group) => {
  //   if (group === 'accountSettings'){
  //     setIsEdittingAccountSettings(!isEdittingAccountSettings);
  //     if(!accountSettingsIsOpen){
  //       setAccountSettingsIsOpen(!accountSettingsIsOpen);
  //     }
  //   } else if (group === 'contactInformation'){
  //     setIsEdittingContactInformation(!isEdittingContactInformation)
  //     if(!contactInformationIsOpen){
  //       setContactInformationIsOpen(!contactInformationIsOpen);
  //     }
  //   }
  // }

  const logoutUser = () => {
    Swal.fire({
      text: t('logoutModal.title'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#6b64ff',
      cancelButtonColor: '#d2d2d7',
      confirmButtonText: t('confirmText'),
      cancelButtonText: t('cancelText')
    }).then((result) => {
      if (result.isConfirmed) {
        logout()
        localStorage.removeItem('user');
        // navigate("/login");        
      }
    })
  }

  const [companyToJoinEmail, setCompanyToJoinEmail] = useState();
  const [joinCompanyModalIsVisible, setJoinCompanyModalIsVisible] = useState(false);
  const toggleJoinCompanyModal = () => {
    setJoinCompanyModalIsVisible(!joinCompanyModalIsVisible)
  }

  const [newCompanyName, setNewCompanyName] = useState();
  const [newCompanyEmail, setNewCompanyEmail] = useState();
  const [newCompanyPhoneNumber, setNewCompanyPhoneNumber] = useState();
  const [createCompanyModalIsVisible, setCreateCompanyModalIsVisible] = useState(false);
  const toggleCreateCompanyModal = () => {
    setCreateCompanyModalIsVisible(!createCompanyModalIsVisible)
  }

  const requestCompanyAccess = async () => {
    try {
      setIsLoading(true);

      const companyRef = query(collection(db, "companies"), where("email", "==", companyToJoinEmail));
      const findCompanies = await getDocs(companyRef);
      if (findCompanies.docs.length > 0){
        
        findCompanies.forEach( async (company) => {          
          const foundCompany = company.data();
          
          await addDoc(collection(db, "accessRequests"), {
            userUid: user.uid,
            userName: userData.name,
            userEmail: userData.email,
            companyUid: foundCompany.uid,
            companyName: foundCompany.name,
            companyEmail: foundCompany.email
          });
        });
        toggleJoinCompanyModal();
        setCompanyToJoinEmail('');
        Swal.fire({
          icon: 'success',
          text: t('accessRequested'),
          confirmButtonColor: '#6b64ff',
          confirmButtonText: 'Okay'
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: t('noCompanyWithThatEmail'),
          confirmButtonColor: '#6b64ff',
          confirmButtonText: 'Okay'
        })
      }
      
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while requesting company access");
    }
  }

  const cancelAccessRequest = async () => {
    Swal.fire({
      text: t('areYouSureYouWantToCancelYourAccessRequest'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#6b64ff',
      cancelButtonColor: '#d2d2d7',
      confirmButtonText: t('confirmText'),
      cancelButtonText: t('cancelText')
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);
    
          const accessRequestsRef = query(collection(db, "accessRequests"), where("userUid", "==", user?.uid));
          const findAccessRequests = await getDocs(accessRequestsRef);
          findAccessRequests.forEach( async (accessRequest) => {
            const getAccessRequest = doc(db, 'accessRequests', accessRequest.id);
            await deleteDoc(getAccessRequest);
          });

          fetchAccessRequests();
          Swal.fire({
            text: t('accessRequestCancelled'),
            icon: 'success',
            confirmButtonColor: '#6b64ff',
            confirmButtonText: t('okay'),
          })
    
          setIsLoading(false);
        }  catch (err) {
          console.error(err);
          alert("An error occured while cancelling company access request");
        }
      }
    })
  }

  const createCompany = async () => {
    try {
      setIsLoading(true);

      await addDoc(collection(db, "companies"), {
        uid: uuidv4(),
        name: newCompanyName,
        email: newCompanyEmail,
        phoneNumber: newCompanyPhoneNumber,
        owners: [user.uid],
        members: [user.uid],
        admins: [user.uid],
        countries: [],
      });

      toggleCreateCompanyModal();
      setNewCompanyName('');
      setNewCompanyEmail('')
      setNewCompanyPhoneNumber('')

      Swal.fire({
        icon: 'success',
        text: t('companyCreated'),
        confirmButtonColor: '#6b64ff',
        confirmButtonText: 'Okay'
      });

      fetchCompanyData();
      
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while requesting company access");
    }
  }

  if (isLoading) return (
    <div id="loginPage" className='loadingScreenDiv'>
      <BarLoader color="#6b64ff" />
    </div>
  )

    return (
      <div id='accountPage'>
        <Container id='pageContainer'>
          <Row>
            <Col>
              <div className='sectionHeader'>
                <h1>
                  {t('accountPage.title')}
                </h1>
              </div>
              <h2>
                {t('accountPage.yourCompany')}
              </h2>
              {
                company ?
                <div id='accountPageCompanyCard' onClick={() => navigate('/company')}>
                <img
                  id='accountPageCompanyImgMain'
                  alt="itemImage"
                  src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1686934655/22%20SPRINGS/placeholder.png"                          
                />
                <div id='accountPageCompanyCardTextContainer'>
                  <h3>
                    {company?.name}
                  </h3>
                  <div id='accountPageCompanyCardMobileContainer'>
                    <img
                      id='accountPageCompanyImgMobile'
                      className='hideOnDesktop'
                      alt="itemImage"
                      src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1686934655/22%20SPRINGS/placeholder.png"                          
                    />
                    <div>
                  <p>
                    {numberOfCountries} {numberOfCountries > 1 ? t('countries') : t('country')}
                  </p>
                  <p>
                    {/* {numberOfBranchesField(company?.countries)} {numberOfBranchesField(company?.countries) > 1 ? t('branches') : t('branch')} */}
                    {numberOfBranches} {numberOfBranches > 1 ? t('branches') : t('branch')}
                  </p>
                  <p>
                    {/* {company?.members?.length} {company?.members?.length > 1 ? t('members') : t('member')} */}
                    {numberOfMembers} {numberOfMembers > 1 ? t('members') : t('member')}
                  </p>
                  </div>
                  </div>
                </div>
                </div>
                :
                accessRequests?.length > 0 ?
                  <div style={{marginBottom: '1rem'}}>
                    <p style={{marginBottom: 0}}>{t('accessTo')} {accessRequests[0].companyName} {t('requested')}. <span onClick={cancelAccessRequest} className='authLink'>{t('pressHere')}</span> {t('toCancelYourRequest')}</p>
                  </div>
                :
                  <div style={{marginBottom: '1rem'}}>
                    <p style={{marginBottom: 0}}>{t('youreStillNotPartOfACompany')}. <span onClick={toggleJoinCompanyModal} className='authLink'>{t('joinOne')}</span> {t('or')} <span onClick={toggleCreateCompanyModal} className='authLink'>{t('createOne')}</span> {t('toGetStarted')}.</p>
                  </div>
              }
              <h2>
                {t('accountPage.yourProfile')}
              </h2>
              <p className='inputLabel'>
                {t('name')}
              </p>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={t('yourName')}
                className='authInput'
                style={{marginBottom: '0.5rem'}}
              />
              <p className='inputLabel'>
                {t('email')}
              </p>
              <Input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('yourEmail')}
                className='authInput'
                style={{marginBottom: '0.5rem'}}
              />
              <p className='inputLabel'>
                {t('phoneNumber')}
              </p>
              <Input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder={t('yourPhoneNumber')}
                className='authInput'
                style={{marginBottom: '0.5rem'}}
              />
              <Button className='primaryBtn' onClick={updateUser} style={{marginTop: '0.5rem'}} disabled={((userData?.name === name) && (userData?.email === email) && (userData?.phoneNumber === phoneNumber))}>
                {t('save')}
              </Button>
              {/* <div className='accountPageCard'>
                <div className='cardHeader'>
                  <div className='sectionHeaderTextContainer'>
                    <h3>                      
                      Account settings
                      {accountSettingsIsOpen ?
                        <GoChevronUp onClick={() => setAccountSettingsIsOpen(!accountSettingsIsOpen)} />
                        : <GoChevronDown onClick={() => setAccountSettingsIsOpen(!accountSettingsIsOpen)} />
                      }
                    </h3>
                  </div>
                  <Button onClick={() => toggleIsEditting('accountSettings')}>
                    Edit
                  </Button>
                </div>
                <div className={classnames(
									"cardBody",
                  {
                    closed: !accountSettingsIsOpen
                  }
                )}>
                  <div className='accountFieldRow'>
                    <p className='label'>
                      Account ID
                    </p>
                    {isEdittingAccountSettings ? 
                      <Input placeholder="Enter an account ID" value={accountId} onChange={(e) => setAccountId(e.target.value)}/>
                      : <p>
                          {accountId}
                        </p>
                    }
                  </div>
                  <div className='accountFieldRow'>
                    <p className='label'>
                      Account name
                    </p>
                    {isEdittingAccountSettings ? 
                      <Input placeholder="Enter an account name" value={accountName} onChange={(e) => setAccountName(e.target.value)}/>
                      : <p>
                          {accountName}
                        </p>
                    }
                  </div>
                  <div className='accountFieldRow'>
                    <p className='label'>
                      Password
                    </p>
                    {isEdittingAccountSettings ? 
                      <Input placeholder="Enter a password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                      : <p>
                          {password}
                        </p>
                    }
                  </div>
                </div>
              </div>
              <div className='accountPageCard'>
                <div className='cardHeader'>
                  <div className='sectionHeaderTextContainer'>
                    <h3>
                      Contact information
                      {contactInformationIsOpen ?
                        <GoChevronUp onClick={() => setContactInformationIsOpen(!contactInformationIsOpen)} />
                        : <GoChevronDown onClick={() => setContactInformationIsOpen(!contactInformationIsOpen)} />
                      }
                    </h3>
                  </div>
                  <Button onClick={() => toggleIsEditting('contactInformation')}>
                    Edit
                  </Button>
                </div>
                <div className={classnames(
									"cardBody",
                  {
                    closed: !contactInformationIsOpen
                  }
                )}>
                  <div className='accountFieldRow'>
                    <p className='label'>
                      Full name
                    </p>
                    {isEdittingContactInformation ? 
                      <Input placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)}/>
                      : <p>
                          {name}
                        </p>
                    }
                  </div>
                  <div className='accountFieldRow'>
                    <p className='label'>
                      Address
                    </p>
                    {isEdittingContactInformation ? 
                      <Input placeholder="Enter your address" value={address} onChange={(e) => setAddress(e.target.value)}/>
                      : <p>
                          {address}
                        </p>
                    }
                  </div>
                  <div className='accountFieldRow'>
                    <p className='label'>
                      City
                    </p>
                    {isEdittingContactInformation ? 
                      <Input placeholder="Enter your city" value={city} onChange={(e) => setCity(e.target.value)}/>
                      : <p>
                          {city}
                        </p>
                    }
                  </div>
                  <div className='accountFieldRow'>
                    <p className='label'>
                      State
                    </p>
                    {isEdittingContactInformation ? 
                      <Input placeholder="Enter your state" value={state} onChange={(e) => setState(e.target.value)}/>
                      : <p>
                          {state}
                        </p>
                    }
                  </div>
                  <div className='accountFieldRow'>
                    <p className='label'>
                      Postal code
                    </p>
                    {isEdittingContactInformation ? 
                      <Input placeholder="Enter your postal code" value={postalCode} onChange={(e) => setPostalCode(e.target.value)}/>
                      : <p>
                          {postalCode}
                        </p>
                    }
                  </div>
                  <div className='accountFieldRow'>
                    <p className='label'>
                      Country
                    </p>
                    {isEdittingContactInformation ? 
                      <Input placeholder="Enter your country" value={country} onChange={(e) => setCountry(e.target.value)}/>
                      : <p>
                          {country}
                        </p>
                    }
                  </div>
                  <div className='accountFieldRow'>
                    <p className='label'>
                      Phone number
                    </p>
                    {isEdittingContactInformation ? 
                      <Input placeholder="Enter your phone number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
                      : <p>
                          {phoneNumber}
                        </p>
                    }
                  </div>
                  <div className='accountFieldRow'>
                    <p className='label'>
                      Company name
                    </p>
                    {isEdittingContactInformation ? 
                      <Input placeholder="Enter your company name" value={companyName} onChange={(e) => setCompanyName(e.target.value)}/>
                      : <p>
                          {companyName}
                        </p>
                    }
                  </div>
                  <div className='accountFieldRow'>
                    <p className='label'>
                      Website URL
                    </p>
                    {isEdittingContactInformation ? 
                      <Input placeholder="Enter your website URL" value={websiteUrl} onChange={(e) => setWebsiteUrl(e.target.value)}/>
                      : <p>
                          {websiteUrl}
                        </p>
                    }
                  </div>
                </div> */}
              {/* </div> */}
              <Button className='secondaryBtn' onClick={logoutUser} style={{marginTop: '0.5rem'}}>
                {t('logout')}
              </Button>
            </Col>
          </Row>
        </Container>
        <Modal id='joinCompanyModal' isOpen={joinCompanyModalIsVisible} toggle={toggleJoinCompanyModal} size={'lg'}>        
        	<ModalBody>
						<div className='modalHeader' style={{marginBottom: '1rem'}}>
							<div>
								<h1>{t('joinCompany')}</h1>																						
							</div>
							<IoClose onClick={toggleJoinCompanyModal} />
						</div>
            <div>
              <p className='inputLabel'>
                {t('email')}
              </p>
              <Input
                type="text"
                value={companyToJoinEmail}
                onChange={(e) => setCompanyToJoinEmail(e.target.value)}
                placeholder={t('theCompanyToJoinsEmail')}
                className='authInput'
                style={{marginBottom: '0.5rem'}}
              />
              <Button onClick={requestCompanyAccess} className='primaryBtn' style={{marginTop: '0.5rem'}} disabled={!companyToJoinEmail}>
                {t('requestAccess')}
              </Button>
            </div>
          </ModalBody>
        </Modal>
        <Modal id='createCompanyModal' isOpen={createCompanyModalIsVisible} toggle={toggleCreateCompanyModal} size={'lg'}>        
        	<ModalBody>
						<div className='modalHeader' style={{marginBottom: '1rem'}}>
							<div>
								<h1>{t('createCompany')}</h1>																						
							</div>
							<IoClose onClick={toggleCreateCompanyModal} />
						</div>
            <div>
              <p className='inputLabel'>
                {t('name')}
              </p>
              <Input
                type="text"
                value={newCompanyName}
                onChange={(e) => setNewCompanyName(e.target.value)}
                placeholder={t('theNewCompanysName')}
                className='authInput'
                style={{marginBottom: '0.5rem'}}
              />
              <p className='inputLabel'>
                {t('email')}
              </p>
              <Input
                type="text"
                value={newCompanyEmail}
                onChange={(e) => setNewCompanyEmail(e.target.value)}
                placeholder={t('theNewCompanysEmail')}
                className='authInput'
                style={{marginBottom: '0.5rem'}}
              />
              <p className='inputLabel'>
                {t('phoneNumber')}
              </p>
              <Input
                type="text"
                value={newCompanyPhoneNumber}
                onChange={(e) => setNewCompanyPhoneNumber(e.target.value)}
                placeholder={t('theNewCompanysPhoneNumber')}
                className='authInput'
                style={{marginBottom: '0.5rem'}}
              />
              <Button onClick={createCompany} className='primaryBtn' style={{marginTop: '0.5rem'}} disabled={!newCompanyName || !newCompanyEmail || !newCompanyPhoneNumber}>
                {t('create')}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>     
    )
  }

export default AccountPage;