import { Container, Row, Col } from 'reactstrap';

import {useTranslation} from "react-i18next";

function DashboardPage() {
  const {t} = useTranslation('common');

  return (
    <Container id='pageContainer'>
      <Row>
        <Col>
          <div className='sectionHeader'>
            <h1>
              {t('hello')} Diego
            </h1>
          </div>
          <p style={{color: '#868686'}}>
            {t('comingSoon')}
          </p>  
        </Col>
      </Row>
    </Container> 
    )
  }

export default DashboardPage;