import { useState, useEffect, useRef } from 'react';

import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, storage } from "../../util/firebase";
import { query, collection, getDocs, where, doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, listAll, getDownloadURL } from 'firebase/storage';

import {v4 as uuidv4} from 'uuid';

import {useTranslation} from "react-i18next";

import { Container, Row, Col, Modal, ModalBody, Input, Button } from 'reactstrap';

import { IoClose } from 'react-icons/io5';

import Swal from 'sweetalert2';

import Select from 'react-select';

import { useNavigate, useParams } from "react-router-dom";

import { MdChevronLeft } from 'react-icons/md';

import BarLoader from "react-spinners/BarLoader";

function SingleOrderPage() {
  const params = useParams()
  const orderId = params.orderId

  const [user] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation('common');
  const navigate = useNavigate();

  const [order, setOrder] = useState();
  const [orderStatus, setOrderStatus] = useState();
  const fetchOrder = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "orders"), where("orderId", "==", parseInt(orderId)));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      console.log(data)
      setOrder(data);
      setOrderStatus(data.status)
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching order");
    }
  };

  useEffect(() => {
    fetchOrder();
    // eslint-disable-next-line
  }, [orderId]);

  const [userName, setUserName] = useState();
  const fetchUserData = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setUserName(data.name);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching username");
    }
  };

  useEffect(() => {
    if (!user) return navigate("/");
    fetchUserData();
    // eslint-disable-next-line
  }, [user]);

  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemImage, setSelectedItemImage] = useState();
  const [selectedItemBrand, setSelectedItemBrand] = useState();
  const [selectedItemPriceUnit, setSelectedItemPriceUnit] = useState();
  const [selectedItemQuantity, setSelectedItemQuantity] = useState();
  const [selectedItemUnit, setSelectedItemUnit] = useState();
  const [selectedItemYield, setSelectedItemYield] = useState();
  const [selectedItemStatus, setSelectedItemStatus] = useState();
  const [selectedItemHistory, setSelectedItemHistory] = useState();
  const options = [
    { value: 'pending', label: t('pending') },
    { value: 'acquired', label: t('acquired') },
    { value: 'nonexistent', label: t('nonexistent') },
  ];
  const [singleItemModal, setSingleItemModal] = useState(false);
	const toggleSingleItemModal = (item, action) => {
    if (action === 'open'){
      setSelectedItem(item);
      setSelectedItemImage(item.image);
      setSelectedItemBrand(item.brand);
      setSelectedItemPriceUnit(item.priceUnit)
      setSelectedItemQuantity(item.quantity)
      setSelectedItemUnit(item.unit);
      setSelectedItemYield(item.yield);
      setSelectedItemHistory(item.history)
      let selectedItemStatusLabel;      
      if(item.status === 'pending'){
        selectedItemStatusLabel = t('pending')
      } else if(item.status === 'acquired'){
        selectedItemStatusLabel = t('acquired')
      } else if(item.status === 'nonexistent'){
        selectedItemStatusLabel = t('nonexistent')
      }
      setSelectedItemStatus({value: item.status, label: selectedItemStatusLabel})
      console.log(item.status)
      setSingleItemModal(true)
    } else if (action === 'close'){
      setSingleItemModal(false);
    }		
	}
  // const [newItemImage, setNewItemImage] = useState();
  // eslint-disable-next-line
  const [newItemName, setNewItemName] = useState('');
  // eslint-disable-next-line
  const [newItemBrand, setNewItemBrand] = useState('');
  const [newItemPriceUnit, setNewItemPriceUnit] = useState();
  const [newItemQuantity, setNewItemQuantity] = useState();
  // eslint-disable-next-line
  const [newItemUnit, setNewItemUnit] = useState('');
  // eslint-disable-next-line
  const [newItemYield, setNewItemYield] = useState('');
  // eslint-disable-next-line
  const [newItemPriceTotal, setNewItemPriceTotal] = useState();
  const [newItemModal, setNewItemModal] = useState(false);
  const toggleNewItemModal = () => {
    setNewItemModal(!newItemModal)
	}

  useEffect(() => {
    if ((newItemPriceUnit > 0) && (newItemQuantity > 0)){
      setNewItemPriceTotal((newItemQuantity * newItemPriceUnit).toFixed(2));
    }
  }, [newItemPriceUnit, newItemQuantity]);

  const [searchTerms, setSearchTerms] = useState();

  const [itemToAdd, setItemToAdd] = useState();
  const selectItemToAdd = (item) => {
    setItemToAdd(item);
  }

  const itemToAddPriceTotal = () => {
    return (itemToAdd.priceUnit * parseInt(newItemQuantity)).toFixed(2);
  }

  const [items, setItems] = useState();
  const fetchItems = async (companyUid) => {
    try {
      setIsLoading(true);
      let fetchedItems = [];
      const q = query(collection(db, "items"), where("companyUid", "==", companyUid));
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        const newItem = doc.data()
        fetchedItems.push(newItem)
      });
      console.log(fetchedItems)
      setItems(fetchedItems);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching items");
    }
  };

  useEffect(() => {
    if (order?.companyUid){
      fetchItems(order?.companyUid);
    }
    // eslint-disable-next-line
  }, [order?.companyUid]);

  const addItem = async () => {
    // if (newItemName !== '' && newItemBrand !== '' && newItemPriceUnit > 0 && newItemQuantity > 0 && newItemPriceTotal > 0 && newItemUnit !== ''){
      try {
        setIsLoading(true);
        let newItems = order?.items;
        const newItem = {
          // name: newItemName,
          // brand: newItemBrand,
          // image: "https://res.cloudinary.com/da3qtg7t5/image/upload/v1686934655/22%20SPRINGS/placeholder.png",
          // priceUnit: parseFloat(newItemPriceUnit),
          // quantity: parseInt(newItemQuantity),
          // priceTotal: parseFloat(newItemPriceTotal),
          // unit: newItemUnit,
          // uid: uuidv4(),
          // status: "pending",
          // isEditted: false

          uid: uuidv4(),
          image: itemToAdd.image,
          name: itemToAdd.name,
          brand: itemToAdd.brand,
          priceUnit: parseFloat(itemToAdd.priceUnit),
          quantity: parseInt(newItemQuantity),
          unit: itemToAdd.unit,
          yield: itemToAdd.yield,
          priceTotal: parseFloat(itemToAddPriceTotal()),
          status: 'pending',
          isEditted: false
        }
        newItems.push(newItem)
        const orderRef = query(collection(db, "orders"), where("orderId", "==", parseInt(orderId)));
        const findOrders = await getDocs(orderRef);
        findOrders.forEach( async (order) => {
          const getOrder = doc(db, 'orders', order.id);
          await updateDoc(getOrder, {
            items: newItems
          });
        });
        toggleNewItemModal()
        setItemToAdd()
        setNewItemName()
        setNewItemBrand()
        setNewItemPriceUnit()
        setNewItemQuantity()
        setNewItemPriceTotal()
        setNewItemUnit();
        setNewItemYield()
        Swal.fire({
          icon: 'success',
          text: t('itemAddedSuccessfully'),
          confirmButtonColor: '#6b64ff',
          confirmButtonText: 'Okay'
        })
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        alert("An error occured while updating order items");
      }  
    // } else {
    //   Swal.fire({
    //     text: t('completeFormText'),
    //     icon: 'warning',
    //     confirmButtonText: t('understood'),
    //     confirmButtonColor: '#6b64ff',
    //   })
    // }
  }

  const acceptOrder = async () => {
    Swal.fire({
      text: t('acceptOrderModal.title'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#6b64ff',
      cancelButtonColor: '#d2d2d7',
      confirmButtonText: t('confirmText'),
      cancelButtonText: t('cancelText')
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);

          const orderRef = query(collection(db, "orders"), where("orderId", "==", parseInt(orderId)));
          const findOrders = await getDocs(orderRef);
          findOrders.forEach( async (order) => {
            const getOrder = doc(db, 'orders', order.id);
            await updateDoc(getOrder, {
              status: "accepted",
              acceptedByUid: user.uid,
              acceptedByName: userName
            });
          });

          fetchOrder();
          
          Swal.fire({
            icon: 'success',
            text: t('orderAccepted'),
            confirmButtonColor: '#6b64ff',
            confirmButtonText: 'Okay'
          })

          setIsLoading(false);
        } catch (err) {
          console.error(err);
          alert("An error occured while updating order status");
        }
      }
    })
  }

  const [completeOrderModal, setCompleteOrderModal] = useState(false);
  const toggleCompleteOrderModal = () => {
    setCompleteOrderModal(!completeOrderModal);
  }
  const [orderInvoiceNumbers, setOrderInvoiceNumbers] = useState([{count: 0, number: ''}])
  const handleOrderInvoiceNumberChange = (index, e) => {
    let newOrderInvoiceNumbers = [...orderInvoiceNumbers];
    newOrderInvoiceNumbers[index]['number'] = e.target.value;
    setOrderInvoiceNumbers(newOrderInvoiceNumbers);
  }

  const addInvoiceNumber = () => {
    const newInvoiceNumberCount = orderInvoiceNumbers.length;
    const newInvoiceNumber = {count: newInvoiceNumberCount, number: ''};
    setOrderInvoiceNumbers([...orderInvoiceNumbers, newInvoiceNumber]);
  }

  const deleteInvoiceNumber = (count) => {
    const newOrderInvoiceNumbers = orderInvoiceNumbers.filter(invoiceNumber => {
      return invoiceNumber.count !== count;
    });
    setOrderInvoiceNumbers(newOrderInvoiceNumbers);
  }

  const invoicesFetchedRef = useRef(false);
  
  const [invoices, setInvoices] = useState([])
  const fetchInvoices = async () => {
    try {
      setIsLoading(true);
      let fetchedInvoices = [];
      const invoicesRef = ref(storage, `invoices/${orderId}`);
      await listAll(invoicesRef).then((response) => {
        response.items.forEach(item => {
          getDownloadURL(item).then((url) => {
            fetchedInvoices.push(url)
          })
        })
      })
      setInvoices(fetchedInvoices)
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching invoices");
    }
  };

  useEffect(() => {
    if (invoicesFetchedRef.current) return;
    invoicesFetchedRef.current = true;
    fetchInvoices();
    // eslint-disable-next-line
  }, []);

  const hiddenFileInput = useRef(null);

  const chooseFile = () => {
    hiddenFileInput.current.click();
  };

  const [invoiceUpload, setInvoiceUpload] = useState();
  const uploadInvoices = async () => {
    try {
      setIsLoading(true);
      if (invoiceUpload == null) return;
      const imageRef = ref(storage, `invoices/${orderId}/${invoiceUpload.name + uuidv4()}`);
      await uploadBytes(imageRef, invoiceUpload).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          setInvoices((prev) => [...prev, url])
        })
      })
      setInvoiceUpload();
      setIsLoading(false);
  } catch (err) {
    console.error(err);
    alert("An error occured while uploading invoice");
  }
  }
  
  const completeOrder = () => {
    Swal.fire({
      text: t('completeOrderModal.title'),
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#6b64ff',
      cancelButtonColor: '#d2d2d7',
      confirmButtonText: t('confirmText'),
      cancelButtonText: t('cancelText')
    }).then(async (result) => {
      if (result.isConfirmed) {
        let orderInvoiceNumbersArray = []
        orderInvoiceNumbers.forEach(orderInvoiceNumber => {
          orderInvoiceNumbersArray.push(orderInvoiceNumber.number)
        });
        try {
          setIsLoading(true);
          const orderRef = query(collection(db, "orders"), where("orderId", "==", parseInt(orderId)));
          const findOrders = await getDocs(orderRef);
          findOrders.forEach( async (order) => {
            const getOrder = doc(db, 'orders', order.id);
            await updateDoc(getOrder, {
              status: "complete",
              invoiceNumbers: orderInvoiceNumbersArray,
              invoices: invoices
            });
          });
          setOrderStatus("complete")
          Swal.fire({
            icon: 'success',
            text: t('orderComplete'),
            confirmButtonColor: '#6b64ff',
            confirmButtonText: 'Okay'
          })
          setIsLoading(false);
        } catch (err) {
          console.error(err);
          alert("An error occured while updating order status");
        }
        toggleCompleteOrderModal()
      }
      //   try {
      //     setIsLoading(true);
      //     const orderRef = query(collection(db, "orders"), where("orderId", "==", parseInt(orderId)));
      //     const findOrders = await getDocs(orderRef);
      //     findOrders.forEach( async (order) => {
      //       const getOrder = doc(db, 'orders', order.id);
      //       await updateDoc(getOrder, {
      //         status: "complete",
      //       });
      //     });
      //     setOrderStatus("complete")
      //     Swal.fire({
      //       icon: 'success',
      //       text: t('orderComplete'),
      //       confirmButtonColor: '#6b64ff',
      //       confirmButtonText: 'Okay'
      //     })
      //     setIsLoading(false);
      //   } catch (err) {
      //     console.error(err);
      //     alert("An error occured while updating order status");
      //   }
      // }
    })
  }

  const closeOrder = () => {
    Swal.fire({
      text: t('areYouSureYouWantToCloseThisOrder'),
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#6b64ff',
      cancelButtonColor: '#d2d2d7',
      confirmButtonText: t('confirmText'),
      cancelButtonText: t('cancelText')
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);
          const orderRef = query(collection(db, "orders"), where("orderId", "==", parseInt(orderId)));
          const findOrders = await getDocs(orderRef);
          findOrders.forEach( async (order) => {
            const getOrder = doc(db, 'orders', order.id);
            await updateDoc(getOrder, {
              status: "closed",
            });
          });
          setOrderStatus("closed")
          Swal.fire({
            icon: 'success',
            text: t('orderClosed'),
            confirmButtonColor: '#6b64ff',
            confirmButtonText: 'Okay'
          })
          navigate(-1)
          setIsLoading(false);
        } catch (err) {
          console.error(err);
          alert("An error occured while updating order status");
        }
      }
    })
  }

  const itemStatusField = (status) => {
    let statusField;
    if(status === 'pending'){
      statusField = t('pending')
    } else if(status === 'acquired'){
      statusField = t('acquired')
    } else if(status === 'nonexistent'){
      statusField = t('nonexistent')
    }

    return statusField;
  }

  const [historyItemForModal, setHistoryItemForModal] = useState();
  const [historyItemModal, setHistoryItemModal] = useState();
  const toggleHistoryItemModal = (historyItem) => {
    if (historyItemModal){
      setHistoryItemModal(false);
    } else {
      setHistoryItemForModal(historyItem)
      setHistoryItemModal(true);
    }
  }

  if (isLoading) return (
    <div id="loginPage" className='loadingScreenDiv'>
      <BarLoader color="#6b64ff" />
    </div>
  )

  const editSingleItem = async () => {
    try {
      setIsLoading(true);
      let newItems = order?.items;
      let selectedItemPriceUnitFloat = parseFloat(selectedItemPriceUnit);
      let selectedItemQuantityInt = parseInt(selectedItemQuantity)
      let itemIndex = newItems.findIndex((item => item.uid === selectedItem.uid));

      let itemCurrentHistory = newItems[itemIndex].history ? newItems[itemIndex].history : [];

      const newHistoryItem = {
        name: newItems[itemIndex].name,
        brand: newItems[itemIndex].brand,
        image: newItems[itemIndex].image,
        priceTotal: newItems[itemIndex].priceTotal,
        priceUnit: newItems[itemIndex].priceUnit,
        quantity: newItems[itemIndex].quantity,
        unit: newItems[itemIndex].unit,
        yield: newItems[itemIndex].yield,
        status: newItems[itemIndex].status,
        dateChanged: new Date().toISOString(),
        changedBy: userName
      }

      itemCurrentHistory.push(newHistoryItem)

      newItems[itemIndex].brand = selectedItemBrand
      newItems[itemIndex].priceUnit = selectedItemPriceUnitFloat
      newItems[itemIndex].quantity = selectedItemQuantityInt
      newItems[itemIndex].priceTotal = parseFloat(((selectedItemPriceUnitFloat * selectedItemQuantityInt).toFixed(2)))
      newItems[itemIndex].unit = selectedItemUnit
      newItems[itemIndex].yield = selectedItemYield
      newItems[itemIndex].status = selectedItemStatus.value;
      // newItems[itemIndex].history = itemHistory

      let newPriceTotal = 0;
      newItems.forEach(item => {
        newPriceTotal += item.priceTotal;
      })

      const orderRef = query(collection(db, "orders"), where("orderId", "==", parseInt(orderId)));
      const findOrders = await getDocs(orderRef);
      findOrders.forEach( async (order) => {
        const getOrder = doc(db, 'orders', order.id);
        await updateDoc(getOrder, {
          items: newItems,
          priceTotal: newPriceTotal,
          isEditted: true
        });
      });
      fetchOrder();
      toggleSingleItemModal(null, 'close');
      Swal.fire({
        icon: 'success',
        text: t('itemUpdatedSuccessfully'),
        confirmButtonColor: '#6b64ff',
        confirmButtonText: 'Okay'
      })
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while updating order items");
    }  
  }

  return (
    <div id='ordersPage'>
      <Container id='pageContainer'>
        <Row>
          <Col>
            <div className='sectionHeader'>              
              <h1>
                <MdChevronLeft onClick={() => navigate(-1)} />
                {t('order')}
              </h1>
            </div>                        
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '2rem'}}>
              <div>
                <h2>
                  #{order?.orderId} - {order?.branchName}
                </h2>
                <p style={{marginBottom: '0px'}}>
                  {order?.authorName} - {order?.dateCreated}
                </p>
              </div>
              {
                orderStatus === "open" ?
                <button onClick={acceptOrder} id='acceptOrderBtn' className='primaryBtn'>
                  {t('accept')}
                </button> : null
              }            
            </div>
            {
              order?.status !== "closed" && order?.authorUid === user?.uid ?
              <button onClick={toggleNewItemModal} className='secondaryBtn' style={{marginBottom: '1rem'}}>
                {t('addItem')}
              </button>
              : null
            }
            <Modal isOpen={newItemModal} toggle={toggleNewItemModal} size={'lg'}>
              <ModalBody>
              <div className='modalHeader'>		
                <div>
                  <h1 style={{marginBottom: '1rem'}}>
                    {t('addItem')}
                  </h1>
                </div>
								<IoClose onClick={toggleNewItemModal} />
							</div>
              {/* <div>
                <p className='inputLabel'>
                  {t('name')}
                </p>
                <Input
                  type="text"
                  value={newItemName}
                  onChange={(e) => setNewItemName(e.target.value)}
                  placeholder={t('addItemModal.namePlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                />
                <p className='inputLabel'>
                  {t('brand')}
                </p>
                <Input
                  type="text"
                  value={newItemBrand}
                  onChange={(e) => setNewItemBrand(e.target.value)}
                  placeholder={t('addItemModal.brandPlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                />
                <p className='inputLabel'>
                  {t('priceUnit')}
                </p>
                <Input
                  type="text"
                  value={newItemPriceUnit}
                  onChange={(e) => setNewItemPriceUnit(e.target.value)}
                  placeholder={t('addItemModal.unitPricePlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                />
                <p className='inputLabel'>
                  {t('quantity')}
                </p>
                <Input
                  type="text"
                  value={newItemQuantity}
                  onChange={(e) => setNewItemQuantity(e.target.value)}
                  placeholder={t('addItemModal.quantityPlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                />
                <p className='inputLabel'>
                  {t('unit')}
                </p>
                <Input
                  type="text"
                  value={newItemUnit}
                  onChange={(e) => setNewItemUnit(e.target.value)}
                  placeholder={t('addItemModal.unitPlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                />
                <p id='newItemTotal'>Total: ${newItemPriceTotal}</p>
                <button onClick={addItem} className='primaryBtn' style={{marginTop: '1rem'}}>
                  {t('save')}
                </button> */}
                <div>
                <Input
                  type="text"
                  value={searchTerms}
                  onChange={(e) => setSearchTerms(e.target.value)}
                  placeholder={t('search')}
                  className='authInput'
                  style={{marginBottom: '1rem'}}
                />
                <div style={{marginBottom: '1rem'}}>
                <div id='ordersListHeader'>
                <div className='orderItemImage hideOnMobile'>                    
                </div>
                <p className='orderItemName'>
                  {t('name')}
                </p>
                <p className='orderItemBrand'>
                  {t('brand')}
                </p>
                <p className='orderItemPriceUnit hideOnMobile'>
                  {t('priceUnit')}
                </p>
                <p className='orderItemUnit hideOnMobile'>
                  {t('unit')}
                </p>
              </div>
                {
                  // eslint-disable-next-line
                  items?.map(item => {
                    if (itemToAdd){
                      if (item.name === itemToAdd.name){
                        return (
                          <div onClick={() => selectItemToAdd()} className='orderItemContainer' style={{border: itemToAdd?.uid === item.uid ? '2px solid #6b64ff' : '2px solid transparent'}}>
                            <div className='orderItemImage hideOnMobile'>
                              <img
                                alt="itemImage"
                                src={item?.image}
                              />
                            </div>
                            <p className='orderItemName'>
                              {item?.name}
                            </p>
                            <p className='orderItemBrand'>
                              {item?.brand}
                            </p>
                            <p className='orderItemPriceUnit hideOnMobile'>
                              {t('currencyUnit')}{item?.priceUnit?.toFixed(2)}
                            </p>
                            <p className='orderItemUnit hideOnMobile'>
                              {item?.unit}
                            </p>             
                          </div>
                        )
                      }
                    } else {
                    if(item?.isActive){
                      // TODO: SHOW ONLY ITEMS BY COUNTRY
                    // if(item?.country === newItemCountry?.value){
                      if (searchTerms){
                        if (item?.name?.toLowerCase()?.includes(searchTerms.toLowerCase()) || item?.brand?.toLowerCase()?.includes(searchTerms.toLowerCase())) {
                          return (
                            <div onClick={() => selectItemToAdd(item)} className='orderItemContainer' style={{border: itemToAdd?.uid === item.uid ? '2px solid #6b64ff' : '2px solid transparent'}}>
                              <div className='orderItemImage hideOnMobile'>
                                <img
                                  alt="itemImage"
                                  src={item?.image}
                                />
                              </div>
                              <p className='orderItemName'>
                                {item?.name}
                              </p>
                              <p className='orderItemBrand'>
                                {item?.brand}
                              </p>
                              <p className='orderItemPriceUnit hideOnMobile'>
                                {t('currencyUnit')}{item?.priceUnit?.toFixed(2)}
                              </p>
                              <p className='orderItemUnit hideOnMobile'>
                                {item?.unit}
                              </p>             
                            </div>
                          )
                        }
                      } else {
                    return (
                      <div onClick={() => setItemToAdd(item)} className='orderItemContainer' style={{border: itemToAdd?.uid === item.uid ? '2px solid #6b64ff' : '2px solid transparent'}}>
                        <div className='orderItemImage hideOnMobile'>
                          <img
                            alt="itemImage"
                            src={item?.image}
                          />
                        </div>
                        <p className='orderItemName'>
                          {item?.name}
                        </p>
                        <p className='orderItemBrand'>
                          {item?.brand}
                        </p>
                        <p className='orderItemPriceUnit hideOnMobile'>
                          {t('currencyUnit')}{item?.priceUnit?.toFixed(2)}
                        </p>
                        <p className='orderItemUnit hideOnMobile'>
                          {item?.unit}
                        </p>                
                      </div>
                    )
                    }
                    }
                  }}
                  )
                }
                </div>
                {itemToAdd ?
                <div>
                <p className='inputLabel'>
                  {t('quantity')}
                </p>
                <Input
                  type="text"
                  value={newItemQuantity}
                  onChange={(e) => setNewItemQuantity(e.target.value)}
                  placeholder={t('addItemModal.quantityPlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                />
                <p id='newItemTotal'>Total: {t('currencyUnit')}{itemToAddPriceTotal() > 0 ? itemToAddPriceTotal() : 0}</p>
                </div>
                : null}
                <Button onClick={addItem} className='primaryBtn' disabled={!itemToAdd || !newItemQuantity || newItemQuantity < 1}>
                  {t('add')}
                </Button>
              </div>
              </ModalBody>
            </Modal>
            <div id='ordersListHeader'>
              <div className='orderItemImage hideOnMobile'>                    
              </div>
              <p className='orderItemName'>
                {t('name')}
              </p>
              <p className='orderItemBrand'>
                {t('brand')}
              </p>
              <p className='orderItemPriceUnit hideOnMobile'>
                {t('priceUnit')}
              </p>
              <p className='orderItemQuantity hideOnMobile'>
                {t('quantity')}
              </p>
              <p className='orderItemUnit hideOnMobile'>
                {t('unit')}
              </p>
              <p className='orderItemPriceTotal hideOnMobile'>
                Total
              </p>
              <p className='orderItemStatus'>
                Status
              </p>
            </div>
            {
              order?.items.map(item => {
                return (
                  <div onClick={() => toggleSingleItemModal(item, 'open')} className='orderItemContainer'>
                    <div className='orderItemImage hideOnMobile'>
                      <img
                        alt="itemImage"
                        src={item.image}                          
                      />
                    </div>
                    <p className='orderItemName'>
                      {item.name}
                    </p>
                    <p className='orderItemBrand'>
                      {item.brand}
                    </p>
                    <p className='orderItemPriceUnit hideOnMobile'>
                      {t('currencyUnit')}{item.priceUnit.toFixed(2)}
                    </p>
                    <p className='orderItemQuantity hideOnMobile'>
                      {item.quantity}
                    </p>
                    <p className='orderItemUnit hideOnMobile'>
                      {item.unit}
                    </p>
                    <p className='orderItemPriceTotal hideOnMobile'>
                      {t('currencyUnit')}{item.priceTotal.toFixed(2)}
                    </p>
                    <p className='orderItemStatus'>
                      {itemStatusField(item.status)}
                    </p>                    
                  </div>
                )
              })
            }
            <Modal isOpen={singleItemModal} toggle={() => toggleSingleItemModal(null, 'close')} size={'lg'}>
              <ModalBody>
              <div className='modalHeader'>		
                <div>
                  <h1>{selectedItem?.name}</h1>
                  <h2>{selectedItem?.brand}</h2>							
                </div>
								<IoClose onClick={() => toggleSingleItemModal(null, 'close')} />
							</div>
              <div>
                <p className='inputLabel'>
                  {t('image')}
                </p>
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '1rem'}}>
                  <img
                    alt="itemImage"
                    src={selectedItemImage}
                    style={{height: 200, width: 200}}
                  />
                </div>
                <p className='inputLabel'>
                  {t('brand')}
                </p>
                <Input
                  type="text"
                  value={selectedItemBrand}
                  onChange={(e) => setSelectedItemBrand(e.target.value)}
                  placeholder={t('addItemModal.brandPlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                  disabled={(!(order?.acceptedByUid === user?.uid)) && (!(order?.companyAdmins?.includes(user.uid))) && (!(order?.authorUid === user?.uid))}
                />
                <p className='inputLabel'>
                  {t('priceUnit')}
                </p>
                <Input
                  type="number"
                  min={0.01}
                  step={0.01}
                  value={selectedItemPriceUnit}
                  onChange={(e) => setSelectedItemPriceUnit(e.target.value)}
                  placeholder={t('addItemModal.unitPricePlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                  disabled={(!(order?.acceptedByUid === user?.uid)) && (!(order?.companyAdmins?.includes(user.uid))) && (!(order?.authorUid === user?.uid))}
                />
                <p className='inputLabel'>
                  {t('quantity')}
                </p>
                <Input
                  type="number"
                  min={1}
                  value={selectedItemQuantity}
                  onChange={(e) => setSelectedItemQuantity(e.target.value)}
                  placeholder={t('addItemModal.quantityPlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                  disabled={(!(order?.acceptedByUid === user?.uid)) && (!(order?.companyAdmins?.includes(user.uid))) && (!(order?.authorUid === user?.uid))}
                />
                <p className='inputLabel'>
                  {t('unit')}
                </p>
                <Input
                  type="text"
                  value={selectedItemUnit}
                  onChange={(e) => setSelectedItemUnit(e.target.value)}
                  placeholder={t('addItemModal.unitPlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                  disabled={(!(order?.acceptedByUid === user?.uid)) && (!(order?.companyAdmins?.includes(user.uid))) && (!(order?.authorUid === user?.uid))}
                />
                <p className='inputLabel'>
                  {t('yield')}
                </p>
                <Input
                  type="text"
                  value={selectedItemYield}
                  onChange={(e) => setSelectedItemYield(e.target.value)}
                  placeholder={t('addItemModal.yieldPlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                  disabled={(!(order?.acceptedByUid === user?.uid)) && (!(order?.companyAdmins?.includes(user.uid))) && (!(order?.authorUid === user?.uid))}
                />
                <p className='inputLabel'>
                  Status
                </p>
                <Select
                  value={selectedItemStatus}
                  onChange={setSelectedItemStatus}
                  options={options}     
                  isDisabled={(!(order?.acceptedByUid === user?.uid)) && (!(order?.companyAdmins?.includes(user.uid))) && (!(order?.authorUid === user?.uid))}        
                />
                <p id='newItemTotal'>Total: {t('currencyUnit')}{(parseFloat(selectedItemPriceUnit) * parseInt(selectedItemQuantity)) > 0 ? (parseFloat(selectedItemPriceUnit) * parseInt(selectedItemQuantity)).toFixed(2) : "0.00"}</p>
                <Button onClick={() => editSingleItem()} className='primaryBtn' style={{marginTop: '1rem', marginBottom: '1rem'}} disabled={(parseFloat(selectedItemPriceUnit) === selectedItem?.priceUnit || !selectedItemPriceUnit) && (parseFloat(selectedItemQuantity) === selectedItem?.quantity || !selectedItemQuantity) && (selectedItemUnit === selectedItem?.unit || !selectedItemUnit) && (selectedItemStatus?.value === selectedItem?.status) && (selectedItemBrand === selectedItem?.brand || !selectedItemBrand) && (selectedItemYield === selectedItem?.yield || !selectedItemYield)}>
                  {t('save')}
                </Button>
                {
                  selectedItemHistory?.length > 0 ?
                    <p className='inputLabel'>
                      {t('history')}
                    </p>
                  : null
                }
                {
                  selectedItemHistory?.length > 0 ?
                  selectedItemHistory?.map(historyItem => {
                    return (
                      <div onClick={() => toggleHistoryItemModal(historyItem)} className='itemHistoryItem'>
                        <p className='hideOnMobile'>
                          {historyItem.brand}
                        </p>
                        <p className='hideOnMobile'>
                          {t('currencyUnit')}{historyItem.priceUnit.toFixed(2)}
                        </p>
                        <p className='hideOnMobile'>
                          {historyItem.quantity} {historyItem.unit}
                        </p>
                        <p className='hideOnMobile'>
                          {t('currencyUnit')}{historyItem.priceTotal.toFixed(2)}
                        </p>
                        <p>
                          {historyItem.changedBy}
                        </p>
                        <p>
                          {new Date(historyItem.dateChanged).toLocaleDateString('de-DE')}
                        </p>
                      </div>
                    )
                  }) : null
                }
              </div>
              </ModalBody>
            </Modal>
            <Modal isOpen={historyItemModal} toggle={toggleHistoryItemModal} size={'lg'}>
              <ModalBody>
                <div className='modalHeader' style={{marginBottom: '1rem'}}>		
                  <div>
                    <h1>
                      {t('itemBefore')} {new Date(historyItemForModal?.dateChanged).toLocaleDateString('de-DE')}
                    </h1>
                  </div>
								  <IoClose onClick={toggleHistoryItemModal} />
							  </div>
                <div>
                  <p className='inputLabel'>
                    {t('changedBy')}
                  </p>
                  <Input
                    type="text"
                    value={historyItemForModal?.changedBy}
                    className='authInput'
                    style={{marginBottom: '0.5rem'}}
                    disabled={true}
                  />
                  <p className='inputLabel'>
                    {t('brand')}
                  </p>
                  <Input
                  type="text"
                  value={historyItemForModal?.brand}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                  disabled={true}
                  />
                <p className='inputLabel'>
                  {t('priceUnit')}
                </p>
                <Input
                  type="text"
                  value={historyItemForModal?.priceUnit}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                  disabled={true}
                  />
                <p className='inputLabel'>
                  {t('quantity')}
                </p>
                <Input
                  type="text"
                  value={historyItemForModal?.quantity}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                  disabled={true}
                  />
                <p className='inputLabel'>
                  {t('unit')}
                </p>
                <Input
                  type="text"
                  value={historyItemForModal?.unit}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                  disabled={true}
                  />
                <p className='inputLabel'>
                  Status
                </p>
                <Input
                  type="text"
                  value={historyItemForModal?.status[0].toUpperCase() + historyItemForModal?.status.slice(1)}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                  disabled={true}
                  />
                <p id='newItemTotal'>Total: {t('currencyUnit')}{(parseFloat(historyItemForModal?.priceUnit) * parseInt(historyItemForModal?.quantity)) > 0 ? (parseFloat(historyItemForModal?.priceUnit) * parseInt(historyItemForModal?.quantity)).toFixed(2) : "0.00"}</p>
                </div>
              </ModalBody>
            </Modal>
            <p id='singleOrderTotal'>Total: {t('currencyUnit')}{order?.priceTotal.toFixed(2)}</p>
            {
              invoices.length > 0 ?
                <div>
                  <h2 style={{marginBottom: '1rem'}}>
                    {t('invoices')}
                  </h2>
                  {
                    invoices?.map(invoice => {
                      return (
                        <img
                          src={invoice}
                          alt="Thumb"
                          style={{height: 150, marginRight: '0.5rem', marginBottom: '0.5rem', cursor: 'pointer'}}
                          onClick={() => window.open(invoice)}
                        />
                      )
                    })
                  }
                </div>
              : null
            }
            {
              orderStatus === "accepted" && (order?.acceptedByUid === user.uid) ?
                <button onClick={toggleCompleteOrderModal} className='primaryBtn' style={{marginTop: '1rem'}}>
                  {t('completeOrder')}
                </button>
              : ((orderStatus === "complete") && (order?.authorUid === user.uid)) ?
              <button onClick={closeOrder} className='primaryBtn' style={{marginTop: '1rem'}}>
                {t('closeOrder')}
              </button>
              : null
            }
            <Modal isOpen={completeOrderModal} toggle={toggleCompleteOrderModal} size={'lg'}>
              <ModalBody>
                <div className='modalHeader'>		
                  <div>
                    <h1 style={{marginBottom: '1rem'}}>
                      {t('completeOrder')}  
                    </h1>
                  </div>
								  <IoClose onClick={toggleCompleteOrderModal} />
							  </div>
                <div>
                <div id='completeOrderModalBody'>
                  <div id='completeOrderModalInvoiceNumbers'>
                    {orderInvoiceNumbers?.map(invoiceNumber => {
                      return (
                        <div>
                          <p className='inputLabel'>
                            {t('invoiceNumber')} {invoiceNumber.count + 1}
                          </p>
                          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Input
                              type="text"
                              value={invoiceNumber.number}
                              onChange={(e) => handleOrderInvoiceNumberChange(invoiceNumber.count, e)}
                              placeholder={t('thePurchasesInvoiceNumber')}
                              className='authInput'
                              style={{marginBottom: '0.5rem'}}
                            />
                            <IoClose onClick={() => deleteInvoiceNumber(invoiceNumber.count)} style={{marginLeft: 5, width: 20, height: 20, marginTop: '-0.5rem', cursor: 'pointer'}}/>
                          </div>
                        </div>
                        )
                      })}
                      <Button onClick={addInvoiceNumber} className='secondaryBtn' style={{marginTop: '0.5rem'}}>
                        {t('addInvoiceNumber')}
                      </Button>
                  </div>
                  <div id='completeOrderModalInvoiceUpload'>
                    <p className='inputLabel'>
                      {t('invoices')}
                    </p>
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                      {
                        order?.invoices?.map(invoice => {
                          return (
                            <img
                              src={invoice}
                              alt="Thumb"
                              style={{height: 150, marginRight: '0.5rem', marginBottom: '0.5rem'}}
                            />
                          )
                        })
                      }
                    </div>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={(e) => setInvoiceUpload(e.target.files[0])}
                      style={{display: 'none'}}
                    />
                    <Button className='primaryBtn' onClick={chooseFile} style={{marginBottom: '0.5rem'}}>
                      {invoiceUpload ? invoiceUpload.name : t('chooseFile')}
                    </Button>
                    <Button className='secondaryBtn' onClick={uploadInvoices} disabled={!invoiceUpload}>
                      {t('uploadInvoice')}
                    </Button>
                  </div>
                  </div>
                  <Button onClick={completeOrder} className='primaryBtn' disabled={!(invoices.length > 0)}>
                    {t('completeOrder')}
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Container> 
    </div>     
  )
}

export default SingleOrderPage;