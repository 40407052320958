import { useState, useEffect } from 'react';

import {v4 as uuidv4} from 'uuid';

import moment from 'moment';

import { auth, db } from "../../util/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { query, collection, getDocs, where, addDoc, updateDoc, doc } from "firebase/firestore";

import {useTranslation} from "react-i18next";

import { Container, Row, Col, Modal, ModalBody, Input, Button } from 'reactstrap';

import Select from 'react-select';

import { IoClose } from 'react-icons/io5';

import Swal from 'sweetalert2';

import { useNavigate } from "react-router-dom";

import { MdChevronLeft } from 'react-icons/md';

import BarLoader from "react-spinners/BarLoader";

const order = 
  {
    id: 1,
    orderNumber: '000001',
    dateCreated: '14.06.2023',
    author: 'Diego Spranger',
    status: 'open',
    priceTotal: 0.00,
    items: [
      {
        id: 1,
        image: 'https://res.cloudinary.com/da3qtg7t5/image/upload/v1686934655/22%20SPRINGS/placeholder.png',
        name: 'Nombre',
        brand: 'Marca',
        priceUnit: 1.99,
        quantity: 10,
        unit: '750ml',
        priceTotal: 19.99,
        status: 'pending'
      },
      {
        id: 2,
        image: 'https://res.cloudinary.com/da3qtg7t5/image/upload/v1686934655/22%20SPRINGS/placeholder.png',
        name: 'Nombre',
        brand: 'Marca',
        priceUnit: 0.50,
        quantity: 25,
        unit: '100g',
        priceTotal: 12.50,
        status: 'acquired'
      },
      {
        id: 3,
        image: 'https://res.cloudinary.com/da3qtg7t5/image/upload/v1686934655/22%20SPRINGS/placeholder.png',
        name: 'Nombre',
        brand: 'Marca',
        priceUnit: 2.25,
        quantity: 12,
        unit: '5ml',
        priceTotal: 27.00,
        status: 'pending'
      }
    ]
  }

function CreateOrderPage() {
  const [user] = useAuthState(auth);

  const [isLoading, setIsLoading] = useState(false);

  const {t} = useTranslation('common');

  const navigate = useNavigate();

  const [searchTerms, setSearchTerms] = useState();

  const [newOrderCreator, setNewOrderCreator] = useState();
  const fetchUserName = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setNewOrderCreator(data.name);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  const [company, setCompany] = useState();
  const fetchCompanyData = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "companies"), where("members", "array-contains", user?.uid));
      const doc = await getDocs(q);
      if (doc.size > 0){
        const data = doc.docs[0].data();
        setCompany(data);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching company data");
    }
  };

  useEffect(() => {
    fetchCompanyData();
    // eslint-disable-next-line
  }, []);

  const [userBranches, setUserBranches] = useState();
  const fetchUserBranches = async () => {
    try {
      setIsLoading(true);
      let fetchedBranches = [];
      const branchesRef = collection(db, "branches");
      const q = query(branchesRef, where("memberUids", "array-contains", user?.uid))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newBranch = doc.data()
        fetchedBranches.push(newBranch)
      });

      setUserBranches(fetchedBranches);

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    fetchUserName();
    fetchUserBranches();
    // eslint-disable-next-line
  }, [user]);

  const [newOrderBranch, setNewOrderBranch] = useState();
  const branchSelectOptions = () => {
    let options = [];
    userBranches?.forEach(branch => {
      let option = {
        value: branch.uid,
        label: branch.name
      }

      options.push(option)
    })

    return options
  }

  const [items, setItems] = useState();
  const fetchItems = async (companyUid) => {
    try {
      setIsLoading(true);
      let fetchedItems = [];
      const q = query(collection(db, "items"), where("companyUid", "==", companyUid));
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        const newItem = doc.data()
        fetchedItems.push(newItem)
      });
      console.log(fetchedItems)
      setItems(fetchedItems);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching items");
    }
  };

  useEffect(() => {
    if (newOrderBranch){
      const chosenBranch = userBranches.find(branch => branch.uid === newOrderBranch.value);
      console.log(chosenBranch.companyUid)

      fetchItems(chosenBranch.companyUid);
    }
    // eslint-disable-next-line
  }, [newOrderBranch, userBranches]);

  // eslint-disable-next-line
  const [newOrderDateCreated, setNewOrderDateCreated] = useState(moment().format("DD.MM.YYYY - HH:mm"));
  const [newOrderItems, setNewOrdersItems] = useState([]);

  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemImage, setSelectedItemImage] = useState();
  const [selectedItemPriceUnit, setSelectedItemPriceUnit] = useState();
  const [selectedItemQuantity, setSelectedItemQuantity] = useState();
  const [selectedItemUnit, setSelectedItemUnit] = useState();
  const [selectedItemYield, setSelectedItemYield] = useState();
  const [singleItemModal, setSingleItemModal] = useState(false);
	const toggleSingleItemModal = (item, action) => {
    if (action === 'open'){
      setSelectedItem(item);
      setSelectedItemImage(item.image);
      setSelectedItemPriceUnit(item.priceUnit);
      setSelectedItemQuantity(item.quantity);
      setSelectedItemUnit(item.unit);
      setSelectedItemYield(item.yield);
      setSingleItemModal(true);
    } else if (action === 'close'){
      setSingleItemModal(false);
    }		
	}
  // const [newItemImage, setNewItemImage] = useState();
  // const [newItemName, setNewItemName] = useState('');
  // const [newItemBrand, setNewItemBrand] = useState('');
  // const [newItemPriceUnit, setNewItemPriceUnit] = useState();
  const [newItemQuantity, setNewItemQuantity] = useState();
  // const [newItemUnit, setNewItemUnit] = useState();
  // const [newItemPriceTotal, setNewItemPriceTotal] = useState();
  const [newItemModal, setNewItemModal] = useState(false);
  const toggleNewItemModal = () => {
    setNewItemModal(!newItemModal)
	}

  const createOrder = async () => {
    // console.log(newOrderBranch, newOrderItems)
    if (newOrderBranch && (newOrderItems.length > 0)){
    Swal.fire({
      // title: 'Estas segur@ que quieres salir?',
      text: t('createOrderModal.title'),
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#6b64ff',
      cancelButtonColor: '#d2d2d7',
      confirmButtonText: t('confirmText'),
      cancelButtonText: t('cancelText')
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);
          const q = query(collection(db, "counters"), where("name", "==", "orderCounter"));
          const document = await getDocs(q);
          const data = document.docs[0].data();
          let counterValue = data.counter;
          
          let selectedBranchMembers = []
          
          userBranches.forEach(userBranch => {
            if(userBranch.uid === newOrderBranch.value){
              userBranch.members.forEach(member => {
                selectedBranchMembers.push(member.userUid)
              })
            }
          })

          const newOrder = {
            uid: uuidv4(),
            companyAdmins: company.admins,
            companyUid: company.uid,
            orderId: counterValue,
            dateCreated: newOrderDateCreated,
            dateCreatedIso: new Date().toISOString(),
            authorUid: user.uid,
            authorName: newOrderCreator,
            status: 'open',
            priceTotal: parseFloat(orderTotalField().toFixed(2)),
            items: newOrderItems,
            branchUid: newOrderBranch.value,
            branchName: newOrderBranch.label,
            membersAssociated: selectedBranchMembers
          }

          await addDoc(collection(db, "orders"), newOrder);
          
          document.forEach(async (counter) => {
            const getCounter = doc(db, 'counters', counter.id);
            const newCounterValue = counterValue + 1
            await updateDoc(getCounter, {
              counter: newCounterValue
            });
          });

          Swal.fire({
            icon: 'success',
            text: t('orderCreatedSuccessfully'),
            confirmButtonColor: '#6b64ff',
            confirmButtonText: 'Okay'
          });

          navigate("/orders")

          setIsLoading(false);
        } catch (err) {
          console.error(err);
          alert("An error occured while creating order");
        }
      }
    })
    } else {
      Swal.fire({
        text: t('completeFormText'),
        icon: 'warning',
        confirmButtonText: t('understood'),
        confirmButtonColor: '#6b64ff',
      })
    }
  }

  const [itemToAdd, setItemToAdd] = useState();
  const selectItemToAdd = (item) => {
    setItemToAdd(item);
  }

  const itemToAddPriceTotal = () => {
    return (itemToAdd.priceUnit * parseInt(newItemQuantity)).toFixed(2);
  }

  const addItem = () => {
      let newItem = {
        uid: uuidv4(),
        image: itemToAdd.image,
        name: itemToAdd.name,
        brand: itemToAdd.brand,
        priceUnit: parseFloat(itemToAdd.priceUnit),
        quantity: parseInt(newItemQuantity),
        unit: itemToAdd.unit,
        yield: itemToAdd.yield ? itemToAdd.yield : "",
        priceTotal: parseFloat(itemToAddPriceTotal()),
        status: 'pending',
        isEditted: false,
      }
      let items = newOrderItems;
      items.push(newItem)
      setNewOrdersItems(items);
      setItemToAdd();
      // setNewItemName()
      // setNewItemBrand()
      // setNewItemPriceUnit()
      setNewItemQuantity()
      // setNewItemUnit()
      // setNewItemPriceTotal()
      toggleNewItemModal();
    
  }

  const editItem = () => {
    let items = newOrderItems;
    
    const index = items.findIndex(obj => {
      return obj.name === selectedItem.name;
    });

    items[index].priceUnit = parseFloat(selectedItemPriceUnit);
    items[index].quantity = parseInt(selectedItemQuantity);
    items[index].unit = selectedItemUnit;
    let newPriceTotal = parseFloat(selectedItemPriceUnit) * parseFloat(selectedItemQuantity);
    items[index].priceTotal = newPriceTotal;

    setNewOrdersItems(items);

    Swal.fire({
      text: t('itemUpdatedSuccessfully'),
      icon: 'success',
      confirmButtonText: "Okay",
      confirmButtonColor: '#6b64ff',
    })

    toggleSingleItemModal(null, 'close')
  }

  const deleteItem = () => {
    Swal.fire({
      // title: 'Estas segur@ que quieres salir?',
      text: t('deleteItemModal.title'),
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#6b64ff',
      cancelButtonColor: '#d2d2d7',
      confirmButtonText: t('confirmText'),
      cancelButtonText: t('cancelText')
    }).then((result) => {
      if (result.isConfirmed) {
        const newArray = newOrderItems.filter(object => {
          return ((object.name !== selectedItem.name) && (object.brande !== selectedItem.brand));
        });
        setNewOrdersItems(newArray)
        toggleSingleItemModal(null, 'close')     
      }      
    })
  }

  // useEffect(() => {
  //   if ((newItemPriceUnit > 0) && (newItemQuantity > 0)){
  //     setNewItemPriceTotal((newItemQuantity * newItemPriceUnit).toFixed(2));
  //   }
  // }, [newItemPriceUnit, newItemQuantity])

  const orderTotalField = () => {
    let total = 0;
    newOrderItems.forEach(item => {
      total += item.priceTotal;
    })

    return total;
  }

  if (isLoading) return (
    <div id="loginPage" className='loadingScreenDiv'>
      <BarLoader color="#6b64ff" />
    </div>
  )

  return (
    <div id='ordersPage'>
      <Container id='pageContainer'>
        <Row>
          <Col>
            <div className='sectionHeader'>              
              <h1 style={{marginBottom: 0}}>
                <MdChevronLeft onClick={() => navigate("/orders")} />
                {t('navbar.createOrderItem')}
              </h1>
            </div>
            {
              company ?
              <div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '2rem'}}>
              <div>
                {/* <h2>
                  #{newOrderId}
                </h2> */}
                <p style={{marginBottom: '0px'}}>
                  {newOrderCreator} - {newOrderDateCreated}
                </p>
              </div>
            </div>
            <div>
              <Select
                value={newOrderBranch}
                onChange={setNewOrderBranch}
                options={branchSelectOptions()}
                placeholder={t('chooseABranchForYourOrder')}
              />
            </div>
            <Button onClick={toggleNewItemModal} className='secondaryBtn' style={{marginBottom: '1rem'}} disabled={!items}>
              {t('addItem')}
            </Button>
            <Modal isOpen={newItemModal} toggle={toggleNewItemModal} size={'lg'}>
              <ModalBody>
              <div className='modalHeader'>		
                <div>
                  <h1 style={{marginBottom: '1rem'}}>
                    {t('addItem')}
                  </h1>
                </div>
								<IoClose onClick={toggleNewItemModal} />
							</div>
              <div>
                <Input
                  type="text"
                  value={searchTerms}
                  onChange={(e) => setSearchTerms(e.target.value)}
                  placeholder={t('search')}
                  className='authInput'
                  style={{marginBottom: '1rem'}}
                />
                <div style={{marginBottom: '1rem'}}>
                <div id='ordersListHeader'>
                <div className='orderItemImage hideOnMobile'>                    
                </div>
                <p className='orderItemName'>
                  {t('name')}
                </p>
                <p className='orderItemBrand'>
                  {t('brand')}
                </p>
                <p className='orderItemPriceUnit hideOnMobile'>
                  {t('priceUnit')}
                </p>
                <p className='orderItemUnit hideOnMobile'>
                  {t('unit')}
                </p>
              </div>
                {
                  // eslint-disable-next-line
                  items?.map(item => {
                    if (itemToAdd){
                      if (item.name === itemToAdd.name){
                        return (
                          <div onClick={() => selectItemToAdd()} className='orderItemContainer' style={{border: itemToAdd?.uid === item.uid ? '2px solid #6b64ff' : '2px solid transparent'}}>
                            <div className='orderItemImage hideOnMobile'>
                              <img
                                alt="itemImage"
                                src={item?.image}
                              />
                            </div>
                            <p className='orderItemName'>
                              {item?.name}
                            </p>
                            <p className='orderItemBrand'>
                              {item?.brand}
                            </p>
                            <p className='orderItemPriceUnit hideOnMobile'>
                              {t('currencyUnit')}{item?.priceUnit?.toFixed(2)}
                            </p>
                            <p className='orderItemUnit hideOnMobile'>
                              {item?.unit}
                            </p>             
                          </div>
                        )
                      }
                    } else {
                    if(item?.isActive){
                      // TODO: SHOW ONLY ITEMS BY COUNTRY
                    // if(item?.country === newItemCountry?.value){
                      if (searchTerms){
                        if (item?.name?.toLowerCase()?.includes(searchTerms.toLowerCase()) || item?.brand?.toLowerCase()?.includes(searchTerms.toLowerCase())) {
                          return (
                            <div onClick={() => selectItemToAdd(item)} className='orderItemContainer' style={{border: itemToAdd?.uid === item.uid ? '2px solid #6b64ff' : '2px solid transparent'}}>
                              <div className='orderItemImage hideOnMobile'>
                                <img
                                  alt="itemImage"
                                  src={item?.image}
                                />
                              </div>
                              <p className='orderItemName'>
                                {item?.name}
                              </p>
                              <p className='orderItemBrand'>
                                {item?.brand}
                              </p>
                              <p className='orderItemPriceUnit hideOnMobile'>
                                {t('currencyUnit')}{item?.priceUnit?.toFixed(2)}
                              </p>
                              <p className='orderItemUnit hideOnMobile'>
                                {item?.unit}
                              </p>             
                            </div>
                          )
                        }
                      } else {
                    return (
                      <div onClick={() => setItemToAdd(item)} className='orderItemContainer' style={{border: itemToAdd?.uid === item.uid ? '2px solid #6b64ff' : '2px solid transparent'}}>
                        <div className='orderItemImage hideOnMobile'>
                          <img
                            alt="itemImage"
                            src={item?.image}
                          />
                        </div>
                        <p className='orderItemName'>
                          {item?.name}
                        </p>
                        <p className='orderItemBrand'>
                          {item?.brand}
                        </p>
                        <p className='orderItemPriceUnit hideOnMobile'>
                          {t('currencyUnit')}{item?.priceUnit?.toFixed(2)}
                        </p>
                        <p className='orderItemUnit hideOnMobile'>
                          {item?.unit}
                        </p>                
                      </div>
                    )
                    }
                    }
                  }}
                  )
                }
                </div>
                {itemToAdd ?
                <div>
                <p className='inputLabel'>
                  {t('quantity')}
                </p>
                <Input
                  type="text"
                  value={newItemQuantity}
                  onChange={(e) => setNewItemQuantity(e.target.value)}
                  placeholder={t('addItemModal.quantityPlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                />
                <p id='newItemTotal'>Total: {t('currencyUnit')}{itemToAddPriceTotal() > 0 ? itemToAddPriceTotal() : 0}</p>
                </div>
                : null}
                <Button onClick={addItem} className='primaryBtn' disabled={!itemToAdd || !newItemQuantity || newItemQuantity < 1}>
                  {t('add')}
                </Button>
                {/* <p className='inputLabel'>
                  {t('name')}
                </p>
                <Input
                  type="text"
                  value={newItemName}
                  onChange={(e) => setNewItemName(e.target.value)}
                  placeholder={t('addItemModal.namePlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                />
                <p className='inputLabel'>
                  {t('brand')}
                </p>
                <Input
                  type="text"
                  value={newItemBrand}
                  onChange={(e) => setNewItemBrand(e.target.value)}
                  placeholder={t('addItemModal.brandPlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                />
                <p className='inputLabel'>
                  {t('priceUnit')}
                </p>
                <Input
                  type="text"
                  value={newItemPriceUnit}
                  onChange={(e) => setNewItemPriceUnit(e.target.value)}
                  placeholder={t('addItemModal.unitPricePlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                />
                <p className='inputLabel'>
                  {t('quantity')}
                </p>
                <Input
                  type="text"
                  value={newItemQuantity}
                  onChange={(e) => setNewItemQuantity(e.target.value)}
                  placeholder={t('addItemModal.quantityPlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                />
                <p className='inputLabel'>
                  {t('unit')}
                </p>
                <Input
                  type="text"
                  value={newItemUnit}
                  onChange={(e) => setNewItemUnit(e.target.value)}
                  placeholder={t('addItemModal.unitPlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                /> */}
              </div>
              </ModalBody>
            </Modal>
            {
              newOrderItems.length > 0 ?
                <div id='ordersListHeader'>
                  <div className='orderItemImage hideOnMobile'>                    
                  </div>
                  <p className='orderItemName'>
                    {t('name')}
                  </p>
                  <p className='orderItemBrand'>
                    {t('brand')}
                  </p>
                  <p className='orderItemPriceUnit hideOnMobile'>
                    {t('priceUnit')}
                  </p>
                  <p className='orderItemQuantity'>
                    {t('quantity')}
                  </p>
                  <p className='orderItemUnit hideOnMobile'>
                    {t('unit')}
                  </p>
                  <p className='orderItemPriceTotal hideOnMobile'>
                    Total
                  </p>              
                </div>
                : <p style={{marginBottom: 0, textAlign: 'center'}}>{t('theresNoItemsYet')}...</p>
            }
            {
              newOrderItems?.map(item => {
                return (
                  <div onClick={() => toggleSingleItemModal(item, 'open')} className='orderItemContainer'>
                    <div className='orderItemImage hideOnMobile'>
                      <img
                        alt="itemImage"
                        src={item.image}                          
                      />
                    </div>
                    <p className='orderItemName'>
                      {item.name}
                    </p>
                    <p className='orderItemBrand'>
                      {item.brand}
                    </p>
                    <p className='orderItemPriceUnit hideOnMobile'>
                      {t('currencyUnit')}{item.priceUnit.toFixed(2)}
                    </p>
                    <p className='orderItemQuantity'>
                      {item.quantity}
                    </p>
                    <p className='orderItemUnit hideOnMobile'>
                      {item.unit}
                    </p>
                    <p className='orderItemPriceTotal hideOnMobile'>
                      {t('currencyUnit')}{item.priceTotal.toFixed(2)}
                    </p>                                       
                  </div>
                )
              })
            }
            <Modal isOpen={singleItemModal} toggle={() => toggleSingleItemModal(null, 'close')} size={'lg'}>
              <ModalBody>
              <div className='modalHeader'>		
                <div>
                  <h1>{selectedItem?.name}</h1>
                  <h2>{selectedItem?.brand}</h2>							
                </div>
								<IoClose onClick={() => toggleSingleItemModal(null, 'close')} />
							</div>
              <div>
                <p className='inputLabel'>
                  {t('image')}
                </p>
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '1rem'}}>
                  <img
                    alt="itemImage"
                    src={selectedItemImage}
                    style={{height: 200, width: 200}}
                  />
                </div>
                <p className='inputLabel'>
                  {t('priceUnit')}
                </p>
                <Input
                  type="text"
                  value={selectedItemPriceUnit}
                  onChange={(e) => setSelectedItemPriceUnit(e.target.value)}
                  placeholder={t('addItemModal.unitPricePlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                />
                <p className='inputLabel'>
                  {t('quantity')}
                </p>
                <Input
                  type="text"
                  value={selectedItemQuantity}
                  onChange={(e) => setSelectedItemQuantity(e.target.value)}
                  placeholder={t('addItemModal.quantityPlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                />
                <p className='inputLabel'>
                  {t('unit')}
                </p>
                <Input
                  type="text"
                  value={selectedItemUnit}
                  onChange={(e) => setSelectedItemUnit(e.target.value)}
                  placeholder={t('addItemModal.unitPlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                />
                <p className='inputLabel'>
                  {t('yield')}
                </p>
                <Input
                  type="text"
                  value={selectedItemYield}
                  onChange={(e) => setSelectedItemYield(e.target.value)}
                  placeholder={t('addItemModal.yieldPlaceholder')}
                  className='authInput'
                  style={{marginBottom: '0.5rem'}}
                />
                <button onClick={deleteItem} className='secondaryBtn' style={{marginTop: '1rem'}}>
                  {t('remove')}
                </button>
                <button onClick={editItem} className='primaryBtn' style={{marginTop: '0.5rem'}}>
                  {t('save')}
                </button>
              </div>
              </ModalBody>
            </Modal>
            {
              order.items.length > 0 ?
                <p id='singleOrderTotal'>Total: {t('currencyUnit')}{(orderTotalField()).toFixed(2)}</p> : null
            }
            <Button onClick={createOrder} className='primaryBtn' style={{marginTop: '1rem'}} disabled={!items?.length > 0}>
              {t('create')}
            </Button>
            </div>
              :
              <p style={{marginTop: '2rem', textAlign: 'center'}}>
                {t('CreateOrJoinACompanyToMakeAndViewOrders')}
              </p>
            }
          </Col>
        </Row>
      </Container> 
    </div>     
  )
}

export default CreateOrderPage;