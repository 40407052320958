import { useState, useEffect } from 'react';

import { useParams, useNavigate } from "react-router-dom";

import { db, storage } from "../util/firebase";
import { query, collection, getDocs, where, doc, updateDoc, addDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

import {v4 as uuidv4} from 'uuid';

import { Container, Row, Col, Button, Input, Modal, ModalBody } from 'reactstrap';

import { MdChevronLeft } from 'react-icons/md';

import BarLoader from "react-spinners/BarLoader";

import { IoClose } from 'react-icons/io5';

import Select from 'react-select';

import Swal from 'sweetalert2';

import {useTranslation} from "react-i18next";

function ProductBasePage() {
  const {t} = useTranslation('common');

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const params = useParams();
  const companyId = params.companyId;

  const [searchTerms, setSearchTerms] = useState();
  
  const [newItemCountry, setNewItemCountry] = useState('');
  const [countryOptions, setCountryOptions] = useState()
  const fetchCountries = async () => {
    try {
      setIsLoading(true);
      let newCountryOptions = []
      const q = query(collection(db, "countries"), where("companies", "array-contains", companyId));
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        const newCountry = doc.data()
        newCountryOptions.push({ value: newCountry.name, label: newCountry.name })
      });
      setCountryOptions(newCountryOptions);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching order");
    }
  };

  useEffect(() => {
    fetchCountries();
    // eslint-disable-next-line
  }, []);

  const [newItemImage, setNewItemImage] = useState('https://res.cloudinary.com/da3qtg7t5/image/upload/v1686934655/22%20SPRINGS/placeholder.png');
  const [newItemName, setNewItemName] = useState('');
  const [newItemBrand, setNewItemBrand] = useState('');
  const [newItemPriceUnit, setNewItemPriceUnit] = useState();
  const [newItemUnit, setNewItemUnit] = useState('');
  const [newItemYield, setNewItemYield] = useState('');
  const [newItemModal, setNewItemModal] = useState(false);
  const toggleNewItemModal = () => {
    setNewItemModal(!newItemModal)
	}

  const [items, setItems] = useState();
  const fetchItems = async () => {
    try {
      setIsLoading(true);
      let fetchedItems = [];
      const q = query(collection(db, "items"), where("companyUid", "==", companyId));
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        const newItem = doc.data()
        fetchedItems.push(newItem)
      });
      console.log(fetchedItems)
      setItems(fetchedItems);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching order");
    }
  };

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line
  }, []);

  const addItem = async () => {
    if (newItemName !== ''
    //  && newItemBrand !== '' && newItemPriceUnit > 0 && newItemUnit !== ''
     ){
      try {
        setIsLoading(true);

        const newItem = {
          name: newItemName,
          brand: newItemBrand ? newItemBrand : "",
          image: newItemImage,
          priceUnit: newItemPriceUnit ? parseFloat(newItemPriceUnit) : 0,
          unit: newItemUnit ? newItemUnit : "",
          yield: newItemYield ? newItemYield : "",
          uid: uuidv4(),
          country: newItemCountry?.value,
          companyUid: companyId,
          isActive: true
        }
        console.log(newItem)
        await addDoc(collection(db, "items"), newItem);

        fetchItems()
        toggleNewItemModal()
        setNewItemName()
        setNewItemBrand()
        setNewItemPriceUnit()
        setNewItemUnit();
        setNewItemYield();
        Swal.fire({
          icon: 'success',
          text: t('itemAddedSuccessfully'),
          confirmButtonColor: '#6b64ff',
          confirmButtonText: 'Okay'
        })
        
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        alert("An error occured while updating order items");
      }  
    } else {
      Swal.fire({
        text: t('completeFormText'),
        icon: 'warning',
        confirmButtonText: t('understood'),
        confirmButtonColor: '#6b64ff',
      })
    }
  }

  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemImage, setSelectedItemImage] = useState();
  const [selectedItemName, setSelectedItemName] = useState();
  const [selectedItemBrand, setSelectedItemBrand] = useState();
  const [selectedItemPriceUnit, setSelectedItemPriceUnit] = useState();
  const [selectedItemUnit, setSelectedItemUnit] = useState();
  const [selectedItemYield, setSelectedItemYield] = useState();
  const [selectedItemStatus, setSelectedItemStatus] = useState();
  const options = [
    { value: true, label: t('active') },
    { value: false, label: t('inactive') },
  ];
  const [singleItemModal, setSingleItemModal] = useState(false);
	const toggleSingleItemModal = (item, action) => {
    console.log(item);
    if (action === 'open'){
      setSelectedItem(item);
      setSelectedItemImage(item.image);
      setSelectedItemName(item.name);
      setSelectedItemBrand(item.brand)
      setSelectedItemPriceUnit(item.priceUnit)
      setSelectedItemUnit(item.unit)
      setSelectedItemYield(item.yield)
      let selectedItemStatusLabel;      
      if(item.isActive){
        selectedItemStatusLabel = t('active')
      } else {
        selectedItemStatusLabel = t('inactive')
      }
      setSelectedItemStatus({value: item.isActive, label: selectedItemStatusLabel})
      setSingleItemModal(true)
    } else if (action === 'close'){
      setSingleItemModal(false);
    }		
	}

  const editSingleItem = async () => {
    try {
      setIsLoading(true);
      
      const itemRef = query(collection(db, "items"), where("uid", "==", selectedItem?.uid));
      const findItems = await getDocs(itemRef);
      findItems.forEach( async (item) => {
        const getItem = doc(db, 'items', item.id);
        await updateDoc(getItem, {
          name: selectedItemName,
          brand: selectedItemBrand,
          priceUnit: parseFloat(selectedItemPriceUnit),
          unit: selectedItemUnit,
          yield: selectedItemYield,
          isActive: selectedItemStatus?.value,
          image: selectedItemImage
        });
      });
      fetchItems()
      toggleSingleItemModal(null, 'close')
      Swal.fire({
        icon: 'success',
        text: t('itemUpdatedSuccessfully'),
        confirmButtonColor: '#6b64ff',
        confirmButtonText: 'Okay'
      })
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while updating order items");
    }  
  }

  const [isModalLoading, setIsModalLoading] = useState(false);

  const [addImageModalIsVisible, setAddImageModalIsVisible] = useState(false)
  const toggleAddImageModal = () => {
    setAddImageModalIsVisible(!addImageModalIsVisible);
  }

  const [changeImageModalIsVisible, setChangeImageModalIsVisible] = useState(false)
  const toggleChangeImageModal = () => {
    setChangeImageModalIsVisible(!changeImageModalIsVisible)
  }

  const selectNewItemImage = () => {
    document.getElementById("selectImage").click()
  }
 
  const [newItemImagePreview, setNewItemImagePreview] = useState();
  // Handles input change event and updates state
  const handleChangeNewItemImage = (event) => {
    setNewItemImage(event.target.files[0]);
    setNewItemImagePreview(URL.createObjectURL(event.target.files[0]));
  }

  const handleUploadNewItemImage = () => {
    const storageRef = ref(storage, `itemImages/${companyId}/${newItemCountry?.value}/${newItemImage.name}`);
    const uploadTask = uploadBytesResumable(storageRef, newItemImage);
    setIsModalLoading(true);
    uploadTask.on(
      "state_changed", null ,
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          console.log("File available at", downloadURL);
          try {
            setNewItemImage(downloadURL)
            // const itemRef = query(collection(db, "items"), where("uid", "==", selectedItem?.uid));
            // const findItems = await getDocs(itemRef);
            // findItems.forEach( async (item) => {
            //   const getItem = doc(db, 'items', item.id);
            //   await updateDoc(getItem, {
            //     image: downloadURL
            //   });
            // });
            // fetchItems();
            setIsModalLoading(false);
            toggleAddImageModal();
          } catch (err) {
            console.error(err);
            alert(err.message);
            setIsModalLoading(false);
          }
        });
      }
    );
  }

  const selectSelectedItemImage = () => {
    document.getElementById("selectSelectedItemImage").click()
  }
 
  const [selectedItemImagePreview, setSelectedItemImagePreview] = useState();
  // Handles input change event and updates state
  const handleChangeSelectedItemImage = (event) => {
    setSelectedItemImage(event.target.files[0]);
    setSelectedItemImagePreview(URL.createObjectURL(event.target.files[0]));
  }

  const handleUploadSelectedItemImage = () => {
    const storageRef = ref(storage, `itemImages/${companyId}/${selectedItem?.country}/${selectedItemImage?.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedItemImage);
    setIsModalLoading(true);
    uploadTask.on(
      "state_changed", null ,
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          console.log("File available at", downloadURL);
          try {
            setSelectedItemImage(downloadURL)
            // const itemRef = query(collection(db, "items"), where("uid", "==", selectedItem?.uid));
            // const findItems = await getDocs(itemRef);
            // findItems.forEach( async (item) => {
            //   const getItem = doc(db, 'items', item.id);
            //   await updateDoc(getItem, {
            //     image: downloadURL
            //   });
            // });
            // fetchItems();
            setIsModalLoading(false);
            toggleChangeImageModal();
          } catch (err) {
            console.error(err);
            alert(err.message);
            setIsModalLoading(false);
          }
        });
      }
    );
  }

  if (isLoading) return (
    <div id="loginPage" className='loadingScreenDiv'>
      <BarLoader color="#6b64ff" />
    </div>
  )

  return (
    <div id='productBasePage'>
      <Container id='pageContainer'>
        <Row>
          <Col>
            <div className='sectionHeader'>
              <h1>
                <MdChevronLeft onClick={() => navigate(-1)} />
                {t('productBase')}
              </h1>
            </div>
            <div>
              <Select
                value={newItemCountry}
                onChange={setNewItemCountry}
                options={countryOptions} 
                placeholder={t('chooseACountry')}               
              />
              {
                newItemCountry ?
                  <div>
                  <Button onClick={toggleNewItemModal} className='primaryBtn' style={{marginBottom: '0.5rem'}}>
                    {t('addItem')}
                  </Button>
                  <Modal isOpen={newItemModal} toggle={toggleNewItemModal} size={'lg'}>
                  <ModalBody>
                    <div className='modalHeader'>		
                      <div>
                        <h1 style={{marginBottom: '1rem'}}>
                          {t('addItem')}
                        </h1>
                      </div>
                      <IoClose onClick={toggleNewItemModal} />
                    </div>
                    <div>
                      <p className='inputLabel'>
                        {t('image')}
                      </p>
                      <div style={{display: 'flex', justifyContent: 'center', marginBottom: '1rem'}}>
                        <img
                          alt="itemImage"
                          src={newItemImage}
                          style={{height: 200, width: 200}}
                        />
                      </div>
                      <Button onClick={toggleAddImageModal} className='secondaryBtn' style={{marginBottom: '0.5rem'}}>
                        {t('changeImage')}
                      </Button>
                      <Modal isOpen={addImageModalIsVisible} toggle={toggleAddImageModal} size={'lg'}>
                        <ModalBody>
                          <div className='modalHeader'>		
                            <div>
                              <h1 style={{marginBottom: '1rem'}}>
                                {t('changeImage')}
                              </h1>
                            </div>
                            <IoClose onClick={toggleAddImageModal} />
                          </div>
                          {
                              isModalLoading ?
                              <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <BarLoader color="#6b64ff" />
                              </div>
                              :
                          <div>
                            <div style={{display: 'flex', justifyContent: 'center', marginBottom: '1rem'}}>
                              <img
                                alt="itemImage"
                                src={newItemImagePreview ? newItemImagePreview : newItemImage}
                                style={{height: 200, width: 200}}
                              />
                            </div>
                            <Input
                              id='selectImage'
                              type="file"
                              className="mainInput"
                              accept='image/*'
                              onChange={handleChangeNewItemImage}
                              style={{display: 'none'}}
                            />
                            <Button onClick={selectNewItemImage} className='secondaryBtn' style={{marginBottom: '0.5rem'}}>
                              {
                                newItemImage.name ? newItemImage.name : t('chooseImage')
                              }
                            </Button>
                            <Button onClick={handleUploadNewItemImage} disabled={!newItemImage.name} className='primaryBtn'>
                              {t('save')}
                            </Button>
                          </div>
                          }
                        </ModalBody>
                      </Modal>
                      <p className='inputLabel'>
                        {t('name')}
                      </p>
                      <Input
                        type="text"
                        value={newItemName}
                        onChange={(e) => setNewItemName(e.target.value)}
                        placeholder={t('addItemModal.namePlaceholder')}
                        className='authInput'
                        style={{marginBottom: '0.5rem'}}
                      />
                      <p className='inputLabel'>
                        {t('brand')}
                      </p>
                      <Input
                        type="text"
                        value={newItemBrand}
                        onChange={(e) => setNewItemBrand(e.target.value)}
                        placeholder={t('addItemModal.brandPlaceholder')}
                        className='authInput'
                        style={{marginBottom: '0.5rem'}}
                      />
                      <p className='inputLabel'>
                        {t('priceUnit')}
                      </p>
                      <Input
                        type="text"
                        value={newItemPriceUnit}
                        onChange={(e) => setNewItemPriceUnit(e.target.value)}
                        placeholder={t('addItemModal.unitPricePlaceholder')}
                        className='authInput'
                        style={{marginBottom: '0.5rem'}}
                      />
                      <p className='inputLabel'>
                        {t('unit')}
                      </p>
                      <Input
                        type="text"
                        value={newItemUnit}
                        onChange={(e) => setNewItemUnit(e.target.value)}
                        placeholder={t('addItemModal.unitPlaceholder')}
                        className='authInput'
                        style={{marginBottom: '0.5rem'}}
                      />
                      <p className='inputLabel'>
                        {t('yield')}
                      </p>
                      <Input
                        type="text"
                        value={newItemYield}
                        onChange={(e) => setNewItemYield(e.target.value)}
                        placeholder={t('addItemModal.yieldPlaceholder')}
                        className='authInput'
                        style={{marginBottom: '0.5rem'}}
                      />
                      <button onClick={addItem} className='primaryBtn' style={{marginTop: '1rem'}}>
                        {t('add')}
                      </button>
                    </div>
                    </ModalBody>
                    </Modal>
                  </div>
                :
                  null
              }
              {(newItemCountry && items?.find(obj => {return obj.country === newItemCountry?.value})) ?
              <div>
              <Input
                type="text"
                value={searchTerms}
                onChange={(e) => setSearchTerms(e.target.value)}
                placeholder={t('search')}
                className='authInput'
                style={{marginBottom: '1rem'}}
              />
              <div id='ordersListHeader'>
                <div className='orderItemImage hideOnMobile'>                    
                </div>
                <p className='orderItemName'>
                  {t('name')}
                </p>
                <p className='orderItemBrand'>
                  {t('brand')}
                </p>
                <p className='orderItemPriceUnit hideOnMobile'>
                  {t('priceUnit')}
                </p>
                <p className='orderItemUnit hideOnMobile'>
                  {t('unit')}
                </p>
                <p className='orderItemStatus'>
                  Status
                </p>
              </div>
              {
                // eslint-disable-next-line
                items?.map(item => {
                  if(item?.country === newItemCountry?.value){
                    if (searchTerms){
                      if (item?.name?.toLowerCase()?.includes(searchTerms.toLowerCase()) || item?.brand?.toLowerCase()?.includes(searchTerms.toLowerCase())) {
                        return (
                          <div onClick={() => toggleSingleItemModal(item, 'open')} className='orderItemContainer'>
                            <div className='orderItemImage hideOnMobile'>
                              <img
                                alt="itemImage"
                                src={item?.image}                          
                              />
                            </div>
                            <p className='orderItemName'>
                              {item?.name}
                            </p>
                            <p className='orderItemBrand'>
                              {item?.brand}
                            </p>
                            <p className='orderItemPriceUnit hideOnMobile'>
                              {item?.priceUnit ? item?.priceUnit?.toFixed(2) : null}
                            </p>
                            <p className='orderItemUnit hideOnMobile'>
                              {item?.unit}
                            </p>
                            {item?.isActive ? 
                              <p className='orderItemStatus'>
                                {t('active')}
                              </p> 
                              :
                              <p className='orderItemStatus'>
                                {t('inactive')}
                              </p> 
                            }                   
                          </div>
                        )
                      }
                    } else {
                  return (
                    <div onClick={() => toggleSingleItemModal(item, 'open')} className='orderItemContainer'>
                      <div className='orderItemImage hideOnMobile'>
                        <img
                          alt="itemImage"
                          src={item?.image}                          
                        />
                      </div>
                      <p className='orderItemName'>
                        {item?.name}
                      </p>
                      <p className='orderItemBrand'>
                        {item?.brand}
                      </p>
                      <p className='orderItemPriceUnit hideOnMobile'>
                        {t('currencyUnit')}{item?.priceUnit ? item?.priceUnit?.toFixed(2) : null}
                      </p>
                      <p className='orderItemUnit hideOnMobile'>
                        {item?.unit}
                      </p>
                      {item?.isActive ? 
                        <p className='orderItemStatus'>
                          {t('active')}
                        </p> 
                        :
                        <p className='orderItemStatus'>
                          {t('inactive')}
                        </p> 
                      }                   
                    </div>
                  )
                  }
                  }
                })
              }
              <Modal isOpen={singleItemModal} toggle={() => toggleSingleItemModal(null, 'close')} size={'lg'}>
                <ModalBody>
                  <div className='modalHeader'>		
                    <div>
                      <h1 style={{marginBottom: '1rem'}}>{t('product')}</h1>
                      {/* <h1>{selectedItem?.name}</h1>
                      <h2>{selectedItem?.brand}</h2> */}
                    </div>
								    <IoClose onClick={() => toggleSingleItemModal(null, 'close')} />
							    </div>
                  <div>
                    <p className='inputLabel'>
                      {t('image')}
                    </p>
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '1rem'}}>
                      <img
                        alt="itemImage"
                        src={selectedItemImage}
                        style={{height: 200, width: 200}}
                      />
                    </div>
                    <Button onClick={toggleChangeImageModal} className='secondaryBtn' style={{marginBottom: '0.5rem'}}>
                      {t('changeImage')}
                    </Button>
                    <Modal isOpen={changeImageModalIsVisible} toggle={toggleChangeImageModal} size={'lg'}>
                      <ModalBody>
                        <div className='modalHeader'>		
                          <div>
                            <h1 style={{marginBottom: '1rem'}}>
                              {t('changeImage')}
                            </h1>
                          </div>
                          <IoClose onClick={toggleChangeImageModal} />
                        </div>
                        {
                          isModalLoading ?
                            <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                              <BarLoader color="#6b64ff" />
                            </div>
                            :
                            <div>
                            <div style={{display: 'flex', justifyContent: 'center', marginBottom: '1rem'}}>
                              <img
                                alt="itemImage"
                                src={selectedItemImagePreview ? selectedItemImagePreview : selectedItemImage}
                                style={{height: 200, width: 200}}
                              />
                            </div>
                            <Input
                              id='selectSelectedItemImage'
                              type="file"
                              className="mainInput"
                              accept='image/*'
                              onChange={handleChangeSelectedItemImage}
                              style={{display: 'none'}}
                            />
                            <Button onClick={selectSelectedItemImage} className='secondaryBtn' style={{marginBottom: '0.5rem'}}>
                              {
                                selectedItemImage?.name ? selectedItemImage.name : t('chooseImage')
                              }
                            </Button>
                            <Button onClick={handleUploadSelectedItemImage} disabled={!selectedItemImage?.name} className='primaryBtn'>
                              {t('save')}
                            </Button>
                          </div>
                          }
                        </ModalBody>
                      </Modal>
                    <p className='inputLabel'>
                      {t('name')}
                    </p>
                    <Input
                      type="text"
                      value={selectedItemName}
                      onChange={(e) => setSelectedItemName(e.target.value)}
                      placeholder={t('addItemModal.namePlaceholder')}
                      className='authInput'
                      style={{marginBottom: '0.5rem'}}
                    />
                    <p className='inputLabel'>
                      {t('brand')}
                    </p>
                    <Input
                      type="text"
                      value={selectedItemBrand}
                      onChange={(e) => setSelectedItemBrand(e.target.value)}
                      placeholder={t('addItemModal.brandPlaceholder')}
                      className='authInput'
                      style={{marginBottom: '0.5rem'}}
                    />
                    <p className='inputLabel'>
                      {t('priceUnit')}
                    </p>
                    <Input
                      type="text"
                      value={selectedItemPriceUnit ? selectedItemPriceUnit : null}
                      onChange={(e) => setSelectedItemPriceUnit(e.target.value)}
                      placeholder={t('addItemModal.unitPricePlaceholder')}
                      className='authInput'
                      style={{marginBottom: '0.5rem'}}
                    />
                    <p className='inputLabel'>
                      {t('unit')}
                    </p>
                    <Input
                      type="text"
                      value={selectedItemUnit}
                      onChange={(e) => setSelectedItemUnit(e.target.value)}
                      placeholder={t('addItemModal.unitPlaceholder')}
                      className='authInput'
                      style={{marginBottom: '0.5rem'}}
                    />
                    <p className='inputLabel'>
                      {t('yield')}
                    </p>
                    <Input
                      type="text"
                      value={selectedItemYield}
                      onChange={(e) => setSelectedItemYield(e.target.value)}
                      placeholder={t('addItemModal.yieldPlaceholder')}
                      className='authInput'
                      style={{marginBottom: '0.5rem'}}
                    />
                    <p className='inputLabel'>
                      Status
                    </p>
                    <Select
                      value={selectedItemStatus}
                      onChange={setSelectedItemStatus}
                      options={options}                  
                    />
                    <Button onClick={() => editSingleItem()} className='primaryBtn' disabled={(((selectedItemName === selectedItem?.name) && (selectedItemBrand === selectedItem?.brand) && (parseFloat(selectedItemPriceUnit) === selectedItem?.priceUnit) && (selectedItemUnit === selectedItem?.unit) && (selectedItemStatus?.value === selectedItem?.isActive)) && !(parseFloat(selectedItemPriceUnit) > 0) && (selectedItemUnit === '') && (selectedItemYield === selectedItem?.yield) && (selectedItemImage === selectedItem?.image))}>
                      {t('save')}
                    </Button>
                  </div>
                </ModalBody>
              </Modal>
              </div>
              : null}
            </div>
          </Col>
        </Row>
      </Container> 
    </div>     
  )
}

export default ProductBasePage;