import { useState, useEffect } from 'react';

import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../util/firebase";
import { query, collection, getDocs, where } from "firebase/firestore";

import {useTranslation} from "react-i18next";

import { Container, Row, Col, Button } from 'reactstrap';

import { useNavigate } from "react-router-dom";

import classnames from 'classnames'

import BarLoader from "react-spinners/BarLoader";

function OrdersPage() {
  const [user] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(false);
  
  const {t} = useTranslation('common');

  const navigate = useNavigate();

  const [company, setCompany] = useState();
  const fetchCompanyData = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "companies"), where("members", "array-contains", user?.uid));
      const doc = await getDocs(q);
      if (doc.size > 0){
        const data = doc.docs[0].data();
        setCompany(data);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching company data");
    }
  };

  useEffect(() => {
    fetchCompanyData();
    // eslint-disable-next-line
  }, []);

  const [activeOrders, setActiveOrders] = useState();
  const [closedOrders, setClosedOrders] = useState();
  const fetchOrders = async () => {
    try {
      setIsLoading(true);
      let fetchedActiveOrders = [];
      let fetchedClosedOrders = [];
      const ordersRef = collection(db, "orders");
      const q = query(ordersRef, where("membersAssociated", "array-contains", user?.uid))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newOrder = doc.data();
        if (newOrder.status === 'closed'){
          fetchedClosedOrders.push(newOrder);
        } else {
          fetchedActiveOrders.push(newOrder);
        }
      });

      const sortedActiveOrders = fetchedActiveOrders.sort(
        (objA, objB) => Number(new Date(objB.dateCreatedIso)) - Number(new Date(objA.dateCreatedIso)),
      );

      const sortedClosedOrders = fetchedClosedOrders.sort(
        (objA, objB) => Number(new Date(objB.dateCreatedIso)) - Number(new Date(objA.dateCreatedIso)),
      );

      setActiveOrders(sortedActiveOrders);
      setClosedOrders(sortedClosedOrders);

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching orders");
    }
  };

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line
  }, [user]);

  const [activeTab, setActiveTab] = useState(1);

  if (isLoading) return (
    <div id="loginPage" className='loadingScreenDiv'>
      <BarLoader color="#6b64ff" />
    </div>
  )

  return (
    <div id='ordersPage'>
      <Container id='pageContainer'>
        <Row>
          <Col>
            <div className='sectionHeader'>
              <h1>
                {t('navbar.ordersItem')}
              </h1>
            </div>
            <Button onClick={() => navigate("/orders/create")} className='primaryBtn' disabled={!company}>
              {t('navbar.createOrderItem')}
            </Button>
            <div className='tabsContainer'>
              <button onClick={() => setActiveTab(1)} className={classnames(
									"leftTab",
                  {
                    active: activeTab === 1
                  }
                )}>
                {t('ordersPage.activeTabLabel')}
              </button>
              <button onClick={() => setActiveTab(2)} className={classnames(
									"rightTab",
                  {
                    active: activeTab === 2
                  }
                )}>
                {t('ordersPage.historyTabLabel')}
              </button>
            </div>
            {
              !company ?
                <p style={{marginTop: '2rem', textAlign: 'center'}}>
                  {t('CreateOrJoinACompanyToMakeAndViewOrders')}
                </p>
                : null
            }
            {
              // TODO: UPLOAD INVOICE IMAGE
              activeTab === 1 ?
              // eslint-disable-next-line
                activeOrders?.length > 0 ?
                activeOrders?.map((order) => {
                  return (
                    <div onClick={() => navigate(`/orders/${order.orderId}`)} className='ordersListItem'>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <p id='orderNumber'>#{order?.orderId}</p>
                        <div id='orderItemsQuantity'>
                          <p>{order?.items?.length}</p>
                        </div>
                      </div>
                      <p>{order.dateCreated}</p>
                    </div>
                  )
                })
                :
                company ?
                <p style={{textAlign: 'center', marginTop: '1rem'}}>
                  {t('ordersPage.noActiveOrdersText')}
                </p>
                : null
              :
                closedOrders?.length > 0 ?
                closedOrders?.map((order) => {
                  return (
                    <div onClick={() => navigate(`/orders/${order.orderId}`)} className='ordersListItem'>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <p id='orderNumber'>#{order?.orderId}</p>
                        <div id='orderItemsQuantity'>
                          <p>{order?.items?.length}</p>
                        </div>
                      </div>
                      <p>{order.dateCreated}</p>
                    </div>
                  )
                })
                :
                company ?
                <p style={{textAlign: 'center', marginTop: '1rem'}}>
                  {t('ordersPage.noClosedOrdersText')}
                </p>
                : null
            }                 
          </Col>
        </Row>
      </Container> 
    </div>     
  )
}

export default OrdersPage;