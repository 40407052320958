import { Container, Row, Col } from 'reactstrap';

import {useTranslation} from "react-i18next";

function AnalyticsPage() {
  const {t} = useTranslation('common');

  return (
    <div id='marketplacePage'>
      <Container id='pageContainer'>
        <Row>
          <Col>
            <div className='sectionHeader'>
              <h1>
                {t('navbar.analyticsItem')}
              </h1>
            </div>
            <p style={{color: '#868686'}}>
              {t('comingSoon')}
            </p>                        
          </Col>
        </Row>
      </Container> 
    </div>     
  )
}

export default AnalyticsPage;