import { useState, useEffect, useRef } from 'react'

import {v4 as uuidv4} from 'uuid';

import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../util/firebase";
import { query, collection, getDocs, where, updateDoc, doc, addDoc } from "firebase/firestore";

import emailjs from '@emailjs/browser';

import {useTranslation} from "react-i18next";

import { MdChevronLeft } from 'react-icons/md';
import { IoClose } from 'react-icons/io5';
import { AiOutlineDelete } from 'react-icons/ai';

import classnames from 'classnames'

import { Container, Row, Col, Button, Input, Modal, ModalBody } from 'reactstrap';

import Swal from 'sweetalert2'

import { useNavigate } from "react-router-dom";

import BarLoader from "react-spinners/BarLoader";

function CompanyPage() {
  const [user] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(false);

  const {t, i18n} = useTranslation('common');
  const navigate = useNavigate();

  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [phoneNumber, setPhoneNumber] = useState()

  const [userData, setUserData] = useState();

  const fetchUserData = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setUserData(data);
      
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (!user) return navigate("/");
    fetchUserData();
    // eslint-disable-next-line
  }, [user]);

  const [company, setCompany] = useState();
  const fetchCompanyData = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "companies"), where("members", "array-contains", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setCompany(data);
      setName(data.name);
      setEmail(data.email);
      setPhoneNumber(data.phoneNumber);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching username");
    }
  };

  useEffect(() => {
    fetchCompanyData();
    // eslint-disable-next-line
  }, []);

  const updateCompany = async () => {
    try {
      setIsLoading(true);
      const companyRef = query(collection(db, "companies"), where("members", "array-contains", user?.uid));
      const findCompanies = await getDocs(companyRef);
      findCompanies.forEach( async (companyObject) => {
        const getCompany = doc(db, 'companies', companyObject.id);
        await updateDoc(getCompany, {
          name: name,
          email: email,
          phoneNumber: phoneNumber
        });
      });
      fetchCompanyData()
      Swal.fire({
        icon: 'success',
        text: t('companyUpdatedSuccessfully'),
        confirmButtonColor: '#6b64ff',
        confirmButtonText: 'Okay'
      })
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while updating user data");
    }  
  }

  const [countries, setCountries] = useState();
  const countriesDataFetchedRef = useRef(false);

  const fetchCountries = async () => {
    setIsLoading(true);
    let fetchedCountries = [];
    const countriesRef = collection(db, "countries");
    const q = query(countriesRef, where("companies", "array-contains", company?.uid))
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const newCountry = doc.data()
      fetchedCountries.push(newCountry)
    });

    setCountries(fetchedCountries);
      
    setIsLoading(false);
  };

  useEffect(() => {
    if(company){
      if (countriesDataFetchedRef.current) return;
      countriesDataFetchedRef.current = true;
      fetchCountries();
    }
    // eslint-disable-next-line
  }, [company]);

  const [newCountryName, setNewCountryName] = useState();
  const [addCountryModal, setAddCountryModal] = useState(false);
	const toggleAddCountryModal = () => {
		setAddCountryModal(!addCountryModal);
	}

  const addCountry = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "countries"), where("name", "==", newCountryName));
      const docs = await getDocs(q);
      if (docs.docs.length === 0) {
        await addDoc(collection(db, "countries"), {
          uid: uuidv4(),
          name: newCountryName,
          companies: [company?.uid]
        }).then(() => {
          fetchCountries()
        });
      } else {
        const foundCountry = docs.docs[0].data()
        docs.forEach(async (countryObject) => {
          const getCountry = doc(db, 'countries', countryObject.id);
          await updateDoc(getCountry, {
            companies: [company.uid, ...foundCountry.companies]
          }).then(() => {
            fetchCountries();
          });
        });
      }
      toggleAddCountryModal()
      Swal.fire({
        icon: 'success',
        text: t('countryAddedSuccessfully'),
        confirmButtonColor: '#6b64ff',
        confirmButtonText: 'Okay'
      });
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while adding country");
    }
  }

	const [branchesModal, setBranchesModal] = useState(false);
	const [countryForBranchesModal, setCountryForBranchesModal] = useState();
  const toggleBranchesModal = (country, action) => {
		if(action === 'open'){
			setCountryForBranchesModal(country);
			setBranchesModal(!branchesModal);
			setBranchForBranchDetailsModal(null);
		} else if (action === 'close'){
			setBranchesModal(!branchesModal);
		}
		
	}

  const [branches, setBranches] = useState();
  const dataFetchedRef = useRef(false);

  const fetchBranches = async () => {
    setIsLoading(true);

    let fetchedBranches = [];
    const branchesRef = collection(db, "branches");
    const q = query(branchesRef, where("companyUid", "==", company?.uid))
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const newBranch = doc.data()
      fetchedBranches.push(newBranch)
    });
    setBranches(fetchedBranches);
      
    setIsLoading(false);
  };

  useEffect(() => {
    if(company){
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      fetchBranches();
    }
    // eslint-disable-next-line
  }, [company]);
	
	const [branchForBranchDetailsModal, setBranchForBranchDetailsModal] = useState();
	const [branchName, setBranchName] = useState();
	const [branchAddress, setBranchAddress] = useState();
	const [branchPhoneNumber, setBranchPhoneNumber] = useState();
  const toggleBranchForBranchDetailsModal = (branch) => {
    setBranchForBranchDetailsModal(branch)
    setBranchName(branch.name)
    setBranchAddress(branch.address)
    setBranchPhoneNumber(branch.phoneNumber)
  }

  const updateBranch = async () => {
    try {
      setIsLoading(true);
      const branchRef = query(collection(db, "branches"), where("uid", "==", branchForBranchDetailsModal.uid));
      const findBranches = await getDocs(branchRef);
      findBranches.forEach( async (branchObject) => {
        const getBranch = doc(db, 'branches', branchObject.id);
        await updateDoc(getBranch, {
          name: branchName,
          email: branchAddress,
          phoneNumber: branchPhoneNumber
        });
      });
      Swal.fire({
        icon: 'success',
        text: t('branchUpdatedSuccessfully'),
        confirmButtonColor: '#6b64ff',
        confirmButtonText: 'Okay'
      })
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while updating branch data");
    }  
  }

	const [newBranchName, setNewBranchName] = useState();
	const [newBranchAddress, setNewBranchAddress] = useState();
	const [newBranchPhoneNumber, setNewBranchPhoneNumber] = useState();
	const [createBranchModal, setCreateBranchModal] = useState(false);
	const toggleCreateBranchModal = () => {
		setCreateBranchModal(!createBranchModal);
	}
  const createBranch = async () => {
    try {
      setIsLoading(true);
      const newBranch = {
        uid: uuidv4(),
        name: newBranchName,
        address: newBranchAddress,
        phoneNumber: newBranchPhoneNumber,
        companyUid: company.uid,
        country: countryForBranchesModal.name,
        members: [{role: 'admin', userName: userData.name, userUid: user.uid}],
        memberUids: [user.uid]
      }
      await addDoc(collection(db, "branches"), newBranch);
      Swal.fire({
        icon: 'success',
        text: t('branchCreatedSuccessfully'),
        confirmButtonColor: '#6b64ff',
        confirmButtonText: 'Okay'
      });
      const newBranchesArray = [newBranch, ...branches]
      setBranches(newBranchesArray)
      toggleCreateBranchModal();
      setNewBranchName();
      setNewBranchAddress();
      setNewBranchPhoneNumber();
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const toggleMemberRole = async (member, role) => {
    if (member.role !== role){
      Swal.fire({      
        text: t('areYourSureYouWantToChangeThisUsersRole'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6b64ff',
        cancelButtonColor: '#d2d2d7',
        confirmButtonText: t('confirmText'),
        cancelButtonText: t('cancelText')
      }).then(async (result) => {
        if (result.isConfirmed) {
      try {
        setIsLoading(true);
        let newMembersArray = branchForBranchDetailsModal?.members;
        let objIndex = newMembersArray.findIndex((obj => obj.userUid === member.userUid));
        newMembersArray[objIndex].role = role
        
        const q = query(collection(db, "branches"), where("uid", "==", branchForBranchDetailsModal?.uid));
        const document = await getDocs(q);
        const getBranch = doc(db, 'branches', document.docs[0].id);
        await updateDoc(getBranch, {
          members: newMembersArray
        });
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        alert("An error occured while updating member data");
      }
        }})
    }
  }

	const [newMemberName, setNewMemberName] = useState();
	const [newMemberEmail, setNewMemberEmail] = useState();
  // eslint-disable-next-line
	const [newMemberPhoneNumber, setNewMemberPhoneNumber] = useState();
	const [newMemberRole, setNewMemberRole] = useState('employee');
	const [addMemberModal, setAddMemberModal] = useState(false);
	const toggleAddMemberModal = () => {
		setAddMemberModal(!addMemberModal);
	}

	const numberOfBranchesField = (country) => {
    let numOfBranches = 0
    
    branches?.forEach(branch => {
      if (branch.country === country){
        numOfBranches += 1;
      }
    });

    return numOfBranches;
  }

  const numberOfMembersField = (country) => {
    let numOfMembers = 0

    branches?.forEach(branch => {
      if (branch.country === country.name){
        numOfMembers += branch.members.length
      }
    });

    return numOfMembers;
  }

  const addMember = async () => {
    try {
      setIsLoading(true);
      
      const q = query(collection(db, "users"), where("email", "==", newMemberEmail));
      const docs = await getDocs(q);
      if (docs.docs.length === 0) {
        const emailValues = {
          company_name: company.name,
          new_member_name: newMemberName,
          new_member_email: newMemberEmail
        }

        const language = i18n.language

        if (language === 'en'){
          emailjs.send('gazindo_strato', 'gazindo_lite_invite_en', emailValues, '4BqGxNfmdPk2uT05D')
          .then((result) => {
            console.log(result.text);
          }, (error) => {
            console.log(error.text);
          });
        } else if (language === 'es'){
          emailjs.send('gazindo_strato', 'gazindo_lite_invite_es', emailValues, '4BqGxNfmdPk2uT05D')
          .then((result) => {
            console.log(result.text);
          }, (error) => {
            console.log(error.text);
          });
        }

        Swal.fire({
          icon: 'warning',
          text: t('theMemberYouWantToAddIsNotOnGazindoLiteYet'),
          confirmButtonColor: '#6b64ff',
          confirmButtonText: 'Okay'
        });
        toggleAddMemberModal()
        setNewMemberName()
        setNewMemberEmail()
        setNewMemberPhoneNumber()
        setNewMemberRole('employee')
        setIsLoading(false);
      } else {
        const foundMember = docs.docs[0].data()
        
        const newMember = {
          uid: uuidv4(),
          userUid: foundMember.uid,
          userName: newMemberName,
          userEmail: newMemberEmail,
          // userPhoneNumber: newMemberPhoneNumber,
          role: newMemberRole,
        }
        
        const q = query(collection(db, "branches"), where("uid", "==", branchForBranchDetailsModal?.uid));
        const document = await getDocs(q);
        const getBranch = doc(db, 'branches', document.docs[0].id);
        await updateDoc(getBranch, {
          memberUids: [...branchForBranchDetailsModal?.memberUids, foundMember.uid],
          members: [...branchForBranchDetailsModal?.members, newMember]
        });
        let newBranchForBranchDetailsModal = branchForBranchDetailsModal;
        newBranchForBranchDetailsModal.memberUids = [...branchForBranchDetailsModal?.memberUids, foundMember.uid];
        newBranchForBranchDetailsModal.members = [...branchForBranchDetailsModal?.members, newMember]
        setBranchForBranchDetailsModal(newBranchForBranchDetailsModal);
        const companyRef = query(collection(db, "companies"), where("uid", "==", branchForBranchDetailsModal?.companyUid));
        const findCompanies = await getDocs(companyRef);
        findCompanies.forEach( async (company) => {
          let companyObject = company.data();
          const getCompany = doc(db, 'companies', company.id);
          if (newMemberRole === 'admin'){
            await updateDoc(getCompany, {
              admins: [...companyObject.members, foundMember.uid],
              members: [...companyObject.members, foundMember.uid]
            });
          } else {
            await updateDoc(getCompany, {
              members: [...companyObject.members, foundMember.uid]
            });
          }
        });

        setNewMemberName('');
        setNewMemberEmail('');
        
        toggleAddMemberModal()
        Swal.fire({
          icon: 'success',
          text: t('memberAddedSuccessfully'),
          confirmButtonColor: '#6b64ff',
          confirmButtonText: 'Okay'
        })

        setIsLoading(false);
      }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  }

	const removeMember = (memberUid) => {
		Swal.fire({      
      text: t('removeMemberModal.title'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#6b64ff',
      cancelButtonColor: '#d2d2d7',
      confirmButtonText: t('confirmText'),
			cancelButtonText: t('cancelText')
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);

          let newMembersArray = branchForBranchDetailsModal?.members?.filter(member => {
            return member.userUid !== memberUid;
          });
          let newMemberUidsArray = branchForBranchDetailsModal?.memberUids?.filter(member => {
            return member !== memberUid;
          });
          const q = query(collection(db, "branches"), where("uid", "==", branchForBranchDetailsModal?.uid));
          const document = await getDocs(q);
          const getBranch = doc(db, 'branches', document.docs[0].id);
          await updateDoc(getBranch, {
            memberUids: newMemberUidsArray,
            members: newMembersArray
          });
          let newBranchForBranchDetailsModal = branchForBranchDetailsModal;
          newBranchForBranchDetailsModal.memberUids = newMemberUidsArray;
          newBranchForBranchDetailsModal.members = newMembersArray
          setBranchForBranchDetailsModal(newBranchForBranchDetailsModal);
          const companyRef = query(collection(db, "companies"), where("uid", "==", branchForBranchDetailsModal?.companyUid));
          const findCompanies = await getDocs(companyRef);
          findCompanies.forEach( async (company) => {
            let companyObject = company.data();
            let newCompanyMembersArray = companyObject?.members?.filter(member => {
              return member !== memberUid;
            });
            const getCompany = doc(db, 'companies', company.id);
            await updateDoc(getCompany, {
              members: newCompanyMembersArray
            });
          });

          setIsLoading(false);
        } catch (err) {
          console.error(err);
          alert("An error occured while updating branch data");
        }
      }
    })
	}

  const navigateToProductBase = () => {
    navigate(`/productBase/${company.uid}`);
    toggleBranchesModal(null, 'close');
  }

  if (isLoading) return (
    <div id="loginPage" className='loadingScreenDiv'>
      <BarLoader color="#6b64ff" />
    </div>
  )

    return (
      <div id='companyPage'>
        <Container id='pageContainer'>
          <Row>
            <Col>
              <div className='sectionHeader'>
                <h1>	
									<MdChevronLeft onClick={() => navigate(-1)} />								
                  {t('companyPage.title')}
                </h1>
              </div>    
							<div id='companyPageCompanyContainer'>						
             	 		<p className='inputLabel'>
                		{t('name')}
              		</p>
              		<Input
                		type="text"
                		value={name}
                		onChange={(e) => setName(e.target.value)}
                		placeholder={t('companyPage.namePlaceholder')}
                		className='authInput'
                		style={{marginBottom: '0.5rem'}}
                    disabled={!company?.owners?.includes(user?.uid)}
              		/>
              		<p className='inputLabel'>
                    {t('email')}
              		</p>
              		<Input
                		type="text"
                		value={email}
                		onChange={(e) => setEmail(e.target.value)}
                		placeholder={t('companyPage.emailPlaceholder')}
                		className='authInput'
                		style={{marginBottom: '0.5rem'}}
                    disabled={!company?.owners?.includes(user?.uid)}
              		/>
              		<p className='inputLabel'>
                    {t('phoneNumber')}
              		</p>
              		<Input
                		type="text"
                		value={phoneNumber}
                		onChange={(e) => setPhoneNumber(e.target.value)}
                		placeholder={t('companyPage.phonePlaceholder')}
                		className='authInput'
                		style={{marginBottom: '0.5rem'}}
                    disabled={!company?.owners?.includes(user?.uid)}
              		/>								
							</div>
              {
                console.log(company?.phoneNumber, phoneNumber)
              }
              {(company?.owners?.includes(user?.uid) || company?.admins?.includes(user?.uid)) ?
                <div>
							    <Button onClick={updateCompany} className='primaryBtn' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}} disabled={((company?.name === name) && (company?.email === email) && (company?.phoneNumber === phoneNumber))}>
                    {t('save')}
                  </Button>
                  <Button onClick={navigateToProductBase} className='secondaryBtn'>
                    {t('productBase')}
                  </Button>
                </div>
              : null
              }
							<h2 style={{marginTop: '1rem'}}>
                {(t('countries')).charAt(0).toUpperCase() + (t('countries')).slice(1)}
							</h2>
              {company?.owners?.includes(user?.uid) ?
							  <Button onClick={toggleAddCountryModal} className='primaryBtn' style={{marginBottom: '1rem'}}>
                  {t('add')}
                </Button>
                : null
              }
							{
                countries?.length > 0 ?
								countries?.map(country => {
									  return (
										  <div id='companyPageCountryCard' onClick={() => toggleBranchesModal(country, 'open')}>
                			  <img
                  			  alt="itemImage"
                  			  src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1686934655/22%20SPRINGS/placeholder.png"                          
                			  />
                			  <div id='companyPageCountryCardTextContainer'>
                  			  <h3>
                    			  {country?.name}
                  			  </h3>                  			
                  			  <p>
                    			  {numberOfBranchesField(country?.name)} {(numberOfBranchesField(country?.name) > 1 || numberOfBranchesField(country?.name) === 0) ? t('branches') : t('branch')}
                  			  </p>
                  			  <p>
                    			  {numberOfMembersField(country)} {(numberOfMembersField(country) > 1 || numberOfMembersField(country) === 0) ? t('members') : t('member')}
                  			  </p>
                			  </div>
              			  </div>
									  )
								})
                :
                <p style={{textAlign: 'center'}}>
                  {t('youreStillNotOperatingInAnyCountries')}...
                </p>
							}
              <Modal id='createBranchModal' isOpen={addCountryModal} toggle={toggleAddCountryModal} size={'md'}>        
        				<ModalBody>
									<div className='modalHeader' style={{marginBottom: '1rem'}}>
										<div>
											<h1>{t('addCountry')}</h1>																						
										</div>
										<IoClose onClick={toggleAddCountryModal} />
									</div>
									<p className='inputLabel'>
                    {t('name')}
              		</p>
              		<Input
                		type="text"
                		value={newCountryName}
                		onChange={(e) => setNewCountryName(e.target.value)}
                		placeholder={t('theNameOfTheCountry')}
                		className='authInput'
                		style={{marginBottom: '0.5rem'}}
              		/>
              		<button onClick={addCountry} className='primaryBtn' style={{marginTop: '0.5rem'}}>
                    {t('add')}
									</button>
								</ModalBody>
							</Modal>
							<Modal isOpen={branchesModal} toggle={() => toggleBranchesModal(null, 'close')} size={'lg'}>        
        				<ModalBody>
									<div className='modalHeader'>
										<div>
											<h1>{company?.name}</h1>											
											<h2>{branchForBranchDetailsModal ? <MdChevronLeft id='modalHeaderBackBtn' onClick={() => setBranchForBranchDetailsModal(null)}/> : null} {branchForBranchDetailsModal ? (countryForBranchesModal?.name + ' - ' + branchForBranchDetailsModal?.name) : countryForBranchesModal?.name}</h2>
										</div>
										<IoClose onClick={() => toggleBranchesModal(null, 'close')} />
									</div>									
									{
										branchForBranchDetailsModal ?
											<div>
												<h3 style={{marginBottom: '0.5rem'}}>
													Info
												</h3>
												<p className='inputLabel'>
                          {t('name')}
              					</p>
              					<Input
                					type="text"
                					value={branchName}
                					onChange={(e) => setBranchName(e.target.value)}
                					placeholder={t('createBranchModal.namePlaceholder')}
                					className='authInput'
                					style={{marginBottom: '0.5rem'}}
                          disabled={!company?.owners?.includes(user?.uid)}
              					/>
												<p className='inputLabel'>
                          {t('address')}
              					</p>
              					<Input
                					type="text"
                					value={branchAddress}
                					onChange={(e) => setBranchAddress(e.target.value)}
                					placeholder={t('createBranchModal.addressPlaceholder')}
                					className='authInput'
                					style={{marginBottom: '0.5rem'}}
                          disabled={!company?.owners?.includes(user?.uid)}
              					/>
												<p className='inputLabel'>
                          {t('phoneNumber')}
              					</p>
              					<Input
                					type="text"
                					value={branchPhoneNumber}
                					onChange={(e) => setBranchPhoneNumber(e.target.value)}
                					placeholder={t('createBranchModal.phonePlaceholder')}
                					className='authInput'
                					style={{marginBottom: '0.5rem'}}
                          disabled={!company?.owners?.includes(user?.uid)}
              					/>
                        {company?.admins?.includes(user?.uid) ?
												  <Button onClick={updateBranch} className='primaryBtn' style={{marginTop: '0.5rem'}} disabled={((branchName === branchForBranchDetailsModal?.name) && (branchAddress === branchForBranchDetailsModal.address) && (branchPhoneNumber === branchForBranchDetailsModal.phoneNumber))}>
                            {t('save')}
              					  </Button>
                          : null
                        }
												<h3 style={{marginBottom: '0.5rem', marginTop: '1rem'}}>
                          {(t('members')).charAt(0).toUpperCase() + (t('members')).slice(1)}
												</h3>
                        {company?.admins?.includes(user?.uid) ?
												  <Button onClick={() => toggleAddMemberModal()} className='primaryBtn' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>
                            {t('add')}
              					  </Button>
                          : null
                        }
												{
													branchForBranchDetailsModal?.members?.map(member => {
														return (
															<div className='branchMemberCard'>
																<p>{member?.userName}</p>
																<div id='branchMemberCardButtons' style={{display: 'flex', flexDirection: 'row'}}>
																<div style={{marginRight: '1rem'}}>
                                  {
                                    !company?.owners?.includes(member?.userUid) ?
                                    <div>
                                      <button onClick={() => toggleMemberRole(member, 'admin')} className={classnames(
																		    "leftTab",
                  									    {
                   								 		    active: member.role === 'admin'
                  									    }
                									    )} disabled={!company?.admins?.includes(user?.uid)}>
																		    Admin
																	    </button>
																	    <button onClick={() => toggleMemberRole(member, 'employee')} className={classnames(
																		    "rightTab",
                  									    {
                   								 		    active: member.role === 'employee'
                  									    }
                									    )} disabled={!company?.admins?.includes(user?.uid)}>
																		    {t('employee')}
																	    </button>
                                    </div>
                                    : null
                                  }
																</div>
                                {company?.admins?.includes(user?.uid) && !company?.owners?.includes(member?.userUid) ?
																  <AiOutlineDelete onClick={() => removeMember(member.userUid)}/>
                                  : null
                                }
																</div>
															</div>
														)
													})
												}
											</div>
										:
                    numberOfBranchesField(countryForBranchesModal?.name) > 0 ?
                    // eslint-disable-next-line
										branches?.map(branch => {              
                        if (countryForBranchesModal?.name === branch.country){
											    return (
												    <div id='companyPageCountryCard' onClick={() => toggleBranchForBranchDetailsModal(branch)}>
                					    <img
                  					    alt="itemImage"
                  					    src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1686934655/22%20SPRINGS/placeholder.png"                          
                					    />
                					    <div id='companyPageCountryCardTextContainer'>
                  					    <h3>
                    					    {branch?.name}
                  					    </h3>                  			                  			
                  					    <p>
                    					    {branch.members?.length} {(branch?.members?.length > 1 || branch?.members?.length === 0) ? t('members') : t('member')}
                  					    </p>
                					    </div>
              					    </div>
											    )
                        }
										})
                    :
                    <p style={{color: '#868686', marginBottom: '1.5rem', textAlign: 'center'}}>
                      Aun no tienes sucursales en este pais...
                    </p>
									}									 
									{
										(branchForBranchDetailsModal || !company?.owners?.includes(user?.uid)) ? null
										:
										<button onClick={() => toggleCreateBranchModal()} className='secondaryBtn'>
											{t('createBranch')}
										</button>
									}
        				</ModalBody>
      				</Modal>
							<Modal id='createBranchModal' isOpen={createBranchModal} toggle={toggleCreateBranchModal} size={'md'}>        
        				<ModalBody>
									<div className='modalHeader' style={{marginBottom: '1rem'}}>
										<div>
											<h1>{t('createBranch')}</h1>																						
										</div>
										<IoClose onClick={toggleCreateBranchModal} />
									</div>
									<p className='inputLabel'>
                    {t('name')}
              		</p>
              		<Input
                		type="text"
                		value={newBranchName}
                		onChange={(e) => setNewBranchName(e.target.value)}
                		placeholder={t('createBranchModal.namePlaceholder')}
                		className='authInput'
                		style={{marginBottom: '0.5rem'}}
              		/>
									<p className='inputLabel'>
                    {t('address')}
              		</p>
              		<Input
                		type="text"
                		value={newBranchAddress}
                		onChange={(e) => setNewBranchAddress(e.target.value)}
                		placeholder={t('createBranchModal.addressPlaceholder')}
                		className='authInput'
                		style={{marginBottom: '0.5rem'}}
              		/>
									<p className='inputLabel'>
                    {t('phoneNumber')}
              		</p>
              		<Input
                		type="text"
                		value={newBranchPhoneNumber}
                		onChange={(e) => setNewBranchPhoneNumber(e.target.value)}
                		placeholder={t('createBranchModal.phonePlaceholder')}
                		className='authInput'
                		style={{marginBottom: '0.5rem'}}
              		/>									
              		<Button onClick={createBranch} className='primaryBtn' style={{marginTop: '0.5rem'}} disabled={!newBranchName || !newBranchPhoneNumber || !newBranchPhoneNumber}>
                    {t('create')}
									</Button>
								</ModalBody>
							</Modal>
							<Modal id='addMemberModal' isOpen={addMemberModal} toggle={toggleAddMemberModal} size={'md'}>        
        				<ModalBody>
									<div className='modalHeader' style={{marginBottom: '1rem'}}>
										<div>
											<h1>
                        {t('addMember')}
                      </h1>
										</div>
										<IoClose onClick={toggleAddMemberModal} />
									</div>
									<p className='inputLabel'>
                    {t('name')}
              		</p>
              		<Input
                		type="text"
                		value={newMemberName}
                		onChange={(e) => setNewMemberName(e.target.value)}
                		placeholder={t('theirName')}
                		className='authInput'
                		style={{marginBottom: '0.5rem'}}
              		/>
									<p className='inputLabel'>
                    {t('email')}
              		</p>
              		<Input
                		type="text"
                		value={newMemberEmail}
                		onChange={(e) => setNewMemberEmail(e.target.value)}
                		placeholder={t('theirEmail')}
                		className='authInput'
                		style={{marginBottom: '0.5rem'}}
              		/>
									{/* <p className='inputLabel'>
                    {t('phoneNumber')}
              		</p>
              		<Input
                		type="text"
                		value={newMemberPhoneNumber}
                		onChange={(e) => setNewMemberPhoneNumber(e.target.value)}
                		placeholder={t('theirPhone')}
                		className='authInput'
                		style={{marginBottom: '0.5rem'}}
              		/> */}
									<p className='inputLabel'>
                    {t('role')}
              		</p>
									<div className='toggler'>
										<button onClick={() => setNewMemberRole('admin')} className={classnames(
											"leftTab",
                  		{
                   			active: newMemberRole === 'admin'
                  		}
                		)}>
											Admin
										</button>
										<button onClick={() => setNewMemberRole('employee')} className={classnames(
											"rightTab",
                  		{
                   			active: newMemberRole === 'employee'
                  		}
                		)}>
											{t('employee')}
										</button>
									</div>
              		<Button onClick={addMember} className='primaryBtn' disabled={!newMemberName || !newMemberEmail}>
                    {t('add')}
									</Button>
								</ModalBody>
							</Modal>
            </Col>
          </Row>
        </Container> 
      </div>     
    )
  }

export default CompanyPage;